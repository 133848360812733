export const constants = {
    /**
     * Settings
     * @property databaseId       - 
     * @property loginScriptId       - 
     * @property signupScriptId       - 
     * @property confirmationCodeScriptId       - 
     * @property removeUserScriptId       - 
     * @property resendCodeScriptId       - 
     * @property resetPasswordCheckUsernameScriptId       - 
     * @property resetPasswordSetPasswordScriptId       - 
     */
    Settings: {
        "databaseId": "653df7280f0d316659d4aaec",
        "loginScriptId": "688a1c98-63dc-4447-aa21-f418fdce52b1",
        "signupScriptId": "e6101dd7-df3b-4a1f-9186-89f59be4200d",
        "confirmationCodeScriptId": "685345fa-ea0a-4fdc-b8bb-063c5bd92760",
        "removeUserScriptId": "df82870c-1a79-4197-8fab-69734d04cb47",
        "resendCodeScriptId": "fd5e40ae-ccef-4951-9918-a061ce1c8958",
        "resetPasswordCheckUsernameScriptId": "0fa7a4c6-429b-4d25-aac4-ff474584f92b",
        "resetPasswordSetPasswordScriptId": "ebe66821-ec01-4474-b90a-ae6efaecb321"
    },
    /**
     * CleanForge_Sentinels_App_settings
     * @property database_id       - 
     */
    CleanForge_Sentinels_App_settings: {
        "database_id": "653df7280f0d316659d4aaec"
    }
};
export const routes = {
    "Launch": "launch",
    "Login": "login",
    "Code": "confirmationcode",
    "InitialSetup": "initialsetup",
    "Profile": "profile",
    "PrivacyPolicy": "privacypolicy",
    "Settings": "settings",
    "Onboarding": "onboarding",
    "Tabs": "tabs",
    "Devices": "devices",
    "DeviceDetails": "devicedetails/:deviceID",
    "ForgotPassword": "forgotpassword",
    "ScanDevice": "scandeviceqr",
    "Notes": "notes",
    "Dashboard": "dashboard",
    "Carbon": "carbon",
    "ScanDeviceBLE": "scandeviceble",
    "Installations": "installations",
    "InstallationDetails": "installationdetails/:installationID",
    "ScanDeviceNFC": "scandevicenfc",
    "TermsOfService": "termsofservice",
    "GatewayDeviceManagement": "gatewaymanagement",
    "InstallationAlerts": "installationalerts",
    "DataVisualization": "datavisualization",
    "Signup": "signup",
    "Subscribe": "subscribe",
};
export const pushSettings = {
    appID: 'd1ad4c64-66c1-4b4e-b12b-7cb0f2d79128',
    baseUrl: 'https://api.appery.io/rest/push/reg',
    baseSendUrl: 'https://api.appery.io/rest/push/msg',
    initOptions: {}
};
export const projectInfo = {
    guid: 'd1ad4c64-66c1-4b4e-b12b-7cb0f2d79128',
    name: 'CleanForge Sentinels',
    description: 'The CleanForge Sentinel Companion App seamlessly connects with CleanForge Sentinel devices to monitor and manage forest growth metrics. With NFC and BLE capabilities, it ensures secure commissioning and real-time data visualization for effective carbon credit generation.'
};
export const pwaInfo = {
    name: 'CleanForge Sentinels',
    shortName: 'CleanForge Sentinels',
    description: '',
    icon: 'assets/images/note.png'
};
export const IGNORED_VALUE = Symbol.for("AIO_REST_IGNORED_VALUE");
export const apiHost = "https://api.appery.io";