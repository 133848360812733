import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../../scripts/apperyio/apperyio_mapping_helper';
import {
    Installations
} from '../../scripts/interfaces';
import {
    Input
} from '@angular/core';
import {
    Output
} from '@angular/core';
import {
    EventEmitter
} from '@angular/core';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'CreateDeviceModal.html',
    selector: 'component-create-device-modal',
    styleUrls: ['CreateDeviceModal.css', 'CreateDeviceModal.scss']
})
export class CreateDeviceModal {
    public chooseUserInstallations: Installations;
    public deviceScanValues: any;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    @ViewChild('j_322', {
        static: false
    }) public j_322;
    @ViewChild('j_315', {
        static: false
    }) public j_315;
    ionViewWillEnter(): any {
        const modalElement: any = document.querySelector('ion-modal#open-device-modal');
        if (modalElement) {
            modalElement.addEventListener('onNFCDataReceived', (event: CustomEvent) => {
                this.deviceScanValues = event.detail;
                this.aioChangeDetector.detectChanges();
            });
        }
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    async button1_copy1Click__j_294(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.$a.closeModal();
    }
    async image1_copyIonImgDidLoad__j_296(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Invoke data service */
        this.invokeService_LoadUserInstallationSelectOptions2();
    }
    async selectwrapperselectinstall_copyClick__j_313(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Invoke data service */
        this.invokeService_LoadUserInstallationSelectOptions2();
    }
    async confirmbutton_copyClick__j_325(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Invoke data service */
        this.invokeService_submitNewSentinels();
        /* Run TypeScript */
        this.$a.closeModal();
    }
    async cancelbutton_copyClick__j_327(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.$a.closeModal();
    }
    private $aio_dataServices = {
        "LoadUserInstallationSelectOptions2": "invokeService_LoadUserInstallationSelectOptions2",
        "submitNewSentinels": "invokeService_submitNewSentinels"
    }
    invokeService_LoadUserInstallationSelectOptions2(cb?: Function) {
        this.Apperyio.getService("LoadUserInstallationsLocal_service").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let __aio_tmp_val__: any;
                service.execute({
                    data: data
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.chooseUserInstallations = this.$aio_mappingHelper.updateData(this.chooseUserInstallations, [], this.$aio_mappingHelper.getServiceDataValue("userInstallations", []));
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
    invokeService_submitNewSentinels(cb?: Function) {
        this.Apperyio.getService("Sentinels_create_service").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                data = this.$aio_mappingHelper.updateData(data, ["Status"], 'Active');
                data = this.$aio_mappingHelper.updateData(data, ["installationID", "collName"], 'Installations');
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['X-Appery-Session-Token'], __aio_tmp_val__ = this.$aio_mappingHelper.getServiceDataValue("userSessionToken", []));
                data = this.$aio_mappingHelper.updateData(data, ["SerialNumber"], ((value) => {
                    return value.SerialNum;
                })(this.$aio_mappingHelper.getSubdata(this.deviceScanValues, [])));
                data = this.$aio_mappingHelper.updateData(data, ["BatteryLevel"], ((value) => {
                    return value.BatteryLevel;
                })(this.$aio_mappingHelper.getSubdata(this.deviceScanValues, [])));
                data = this.$aio_mappingHelper.updateData(data, ["DeviceName"], this.$aio_mappingHelper.getComponentPropValue.call(this, 'j_322', 'ionic5inputitem', 'value'));
                data = this.$aio_mappingHelper.updateData(data, ["installationID", "_id"], this.$aio_mappingHelper.getComponentPropValue.call(this, 'j_315', 'ionic5selectitem', 'value'));
                data = this.$aio_mappingHelper.updateData(data, ["LastTransmission"], ((value) => {
                    let date = new Date();
                    const pad = (num: number, size: number): string => {
                        let s = num.toString();
                        while (s.length < size) s = "0" + s;
                        return s;
                    };
                    const year = date.getFullYear();
                    const month = pad(date.getMonth() + 1, 2); // getMonth() is zero-indexed
                    const day = pad(date.getDate(), 2);
                    const hours = pad(date.getHours(), 2);
                    const minutes = pad(date.getMinutes(), 2);
                    const seconds = pad(date.getSeconds(), 2);
                    const milliseconds = pad(date.getMilliseconds(), 3);
                    console.log("New Time:", `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`);
                    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
                })(this.$aio_mappingHelper.getComponentPropValue.call(this, 'j_315', 'ionic5selectitem', 'value')));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
}