import {
    Injectable,
    NgZone
} from '@angular/core';
import _ from "lodash";
import {
    Observable
} from "rxjs";
import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';
import {
    EntityApiService
} from '../apperyio/apperyio';
import {
    HttpClient,
    HttpParams
} from '@angular/common/http';

@Injectable()
class GoogleLoginService {

    window: any = window;

    constructor(private entityAPI: EntityApiService, private Apperyio: ApperyioHelperService, private http: HttpClient, private ngZone: NgZone) {}
    execute(reqOpts ? : any) {
        return new Observable((observer) => {
            try {
                (async() => {
                    let srvName = await this.Apperyio.getGSNameByImpl(this);
                    if (!srvName) {
                        observer.error("Service was not found");
                        return;
                    }
                    let service = this.entityAPI.get(srvName),
                        echo = service.echo,
                        defaults = service.request.data,
                        request,
                        response;
                    if (_.isUndefined(echo)) {
                        /**
                         * Passed request data structure IS RECOMMENDED to match the default request, described in service!
                         * An example of how to merge default request data with passed data (_.extend() should be used with flat objects only):
                         */
                        request = _.extend({}, defaults, reqOpts);
                        
                        const webClientId = request.data && request.data.webClientId ? request.data.webClientId : request.webClientId;

                        if (this.window.cordova) {
                            this.window.plugins.googleplus.login({
                                    'webClientId': webClientId, // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
                                    'offline': true // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
                                },
                                (res) => {
                                    this.ngZone.run(() => {
                                        observer.next(res);
                                        observer.complete();
                                    });

                                },
                                (e) => {
                                    console.error('error', e)
                                    this.ngZone.run(() => {
                                        observer.error(e);
                                    });
                                }
                            );
                        } else {
                            observer.next({
                                "accessToken": "google-access-token",
                                "expires": 1594996518,
                                "expires_in": 3572,
                                "email": "user@appery.io",
                                "idToken": "google-token",
                                "serverAuthCode": "google-server-auth-code",
                                "userId": "1234567890",
                                "displayName": "Joth Smith",
                                "familyName": "Smith",
                                "givenName": "Joth",
                                "imageUrl": "https://appery.io/wp-content/uploads/user_management.png"
                            });
                            observer.complete();
                        }
                    } else {
                        try {
                            echo = JSON.parse(echo);
                        } catch (e) {
                            console.error('error', e);
                            observer.error(e);

                        }
                        observer.next(echo);
                        observer.complete();
                    }
                })()
            } catch (e) {
                console.error('error', e);
                observer.error(e);
            }
        });
    }
}

/*
    Service class should be exported as ExportedClass
*/
export {
    GoogleLoginService as ExportedClass
};