import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../../scripts/apperyio/apperyio_mapping_helper';
import {
    Input
} from '@angular/core';
import {
    $aio_empty_object
} from '../../scripts/interfaces';
import {
    Output
} from '@angular/core';
import {
    EventEmitter
} from '@angular/core';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'DeleteConfirmationModal.html',
    selector: 'component-delete-confirmation-modal',
    styleUrls: ['DeleteConfirmationModal.css', 'DeleteConfirmationModal.scss']
})
export class DeleteConfirmationModal {
    @Input() public triggerId: string;
    @Input() public ObjectToDelete: any;
    @Input() public modalId: string;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.ObjectToDelete = {
            "name": "Default",
            "type": "Sentinel",
            "_id": "123456789"
        };
        this.modalId = "deleteDashboardModal";
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    async button1_copy2Click__j_485(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.$a.closeModal();
    }
    async confirmbutton_copy1Click__j_493(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        if (this.ObjectToDelete.type === "Sentinel") {
            this.$a.execDataService(this, "submitDeleteSentinel"); // Change service_name to created DataSource name
        } else if (this.ObjectToDelete.type === "Installation") {
            this.$a.execDataService(this, "submitDeleteInstallation"); // Change service_name to created DataSource name
        } else {
            console.error("Invalid Object Type");
        }
        /* Run TypeScript */
        this.$a.closeModal();
    }
    async cancelbutton_copy1Click__j_495(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.$a.closeModal();
    }
    private $aio_dataServices = {
        "submitDeleteSentinel": "invokeService_submitDeleteSentinel",
        "submitDeleteInstallation": "invokeService_submitDeleteInstallation"
    }
    invokeService_submitDeleteSentinel(cb?: Function) {
        this.Apperyio.getService("Sentinels_delete_service").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['X-Appery-Session-Token'], __aio_tmp_val__ = this.$aio_mappingHelper.getServiceDataValue("userSessionToken", []));
                params = this.$aio_mappingHelper.updateData(params, ['_id'], ((value) => {
                    return value._id;
                })(this.$aio_mappingHelper.getSubdata(this.ObjectToDelete, [])));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
    invokeService_submitDeleteInstallation(cb?: Function) {
        this.Apperyio.getService("Installations_delete_service").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['X-Appery-Session-Token'], __aio_tmp_val__ = this.$aio_mappingHelper.getServiceDataValue("userSessionToken", []));
                params = this.$aio_mappingHelper.updateData(params, ['_id'], ((value) => {
                    return value._id;
                })(this.$aio_mappingHelper.getSubdata(this.ObjectToDelete, [])));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
}