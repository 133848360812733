import {
    Injectable
} from '@angular/core';
import _ from "lodash";
import {
    Observable
} from "rxjs";
import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';
import {
    EntityApiService
} from '../apperyio/apperyio';
import {
    paramsParse
} from '../apperyio/apperyio_restservice';
import {
    ApperyioConfigService
} from '../apperyio/config_service';
import {
    HttpClient,
    HttpParams
} from '@angular/common/http';
import {
    Platform,
    ToastController
} from '@ionic/angular';
import {
    NFC,
    Ndef
} from '@ionic-native/nfc/ngx';
import {
    Vibration
} from '@ionic-native/vibration/ngx';


@Injectable()
class NFCScannerService {



    isListening: boolean = false;

    constructor(
        private entityAPI: EntityApiService,
        private Apperyio: ApperyioHelperService,
        private nfc: NFC,
        private ndef: Ndef,
        private toastController: ToastController,
        private platform: Platform,
        private vibration: Vibration
    ) {}

    private async presentToast(message: string) {
        const toast = await this.toastController.create({
            message: message,
            duration: 3000
        });
        toast.present();
    }

    private parseNdefTextRecord(record) {
        if (record.tnf === this.ndef.TNF_WELL_KNOWN && this.ndef.textRecord(record.type)) {
            return this.ndef.textHelper.decodePayload(record.payload);
        }
        return null;
    }

    private readNfcAndroid(observer) {
        this.nfc.enabled().then(() => {
            this.isListening = true;
            this.presentToast('Started NFC listening');
            const ndefListenerSubscription = this.nfc.addNdefListener(() => {
                console.log('successfully attached tag discovered listener');
            }, (err) => {
                console.log('error attaching tag discovered listener', err);
                this.presentToast(`Error: ${err}`);
                this.isListening = false;
                observer.error(err);
            }).subscribe((event) => {
                this.vibration.vibrate(500);
                //this.presentToast('NFC tag read');
                //this.presentToast('Event Tag: ' + event.tag);
                console.log("event tag: ", event.tag);
                console.log("ndef message :", event.tag.ndefMessage[0]);
                /*let messageData = this.ndef.decodeMessage(event.tag.ndefMessage[0]);
                console.log("ndef message decoded:",messageData);
                let textRecordsData = messageData.filter(record => this.ndef.textRecord(record.type));
                 console.log("text records :",textRecordsData);
                let textsData = textRecordsData.map(record => this.parseNdefTextRecord(record));
                 console.log("texts Data :",textsData);*/


                // Assuming the status byte and language code ('en') are always present and followed by a JSON string,
                // find the start index of the JSON object, which is the first occurrence of '{'
                const startIndex = event.tag.ndefMessage[0].payload.indexOf(123); // ASCII value of '{' is 123

                // Find the end index of the JSON object, which is the last occurrence of '}'
                const endIndex = event.tag.ndefMessage[0].payload.lastIndexOf(125); // ASCII value of '}' is 125

                // Extract the JSON part of the payload and convert it to a string
                const jsonString = String.fromCharCode(...event.tag.ndefMessage[0].payload.slice(startIndex, endIndex + 1));

                this.isListening = false;
                observer.next({
                    payload: jsonString
                });
                ndefListenerSubscription.unsubscribe();
                observer.complete();
            });
        }).catch((err) => {
            this.presentToast(`Error: ${err}`);
            observer.error(err);
        });
    }

    /*    private readNfcIOS(observer) {
         this.nfc.scanNdef().then(() => {
             this.isListening = true;
             const ndefListenerSubscription = this.nfc.addNdefListener(() => {
                 console.log('successfully attached ndef listener');
             }, (err) => {
                 console.log('error attaching ndef listener', err);
                 this.presentToast(`Error: ${err}`);
                 this.isListening = false;
                 observer.error(err);
             }).subscribe((event) => {
                 this.vibration.vibrate(500);

                 // Assuming the status byte and language code ('en') are always present and followed by a JSON string,
                 // find the start index of the JSON object, which is the first occurrence of '{'
                 const startIndex = event.tag.ndefMessage[0].payload.indexOf(123); // ASCII value of '{' is 123

                 // Find the end index of the JSON object, which is the last occurrence of '}'
                 const endIndex = event.tag.ndefMessage[0].payload.lastIndexOf(125); // ASCII value of '}' is 125

                 // Extract the JSON part of the payload and convert it to a string
                 const jsonString = String.fromCharCode(...event.tag.ndefMessage[0].payload.slice(startIndex, endIndex + 1));

                 this.isListening = false;
                 observer.next({
                     payload: jsonString
                 });
                 ndefListenerSubscription.unsubscribe();
                 observer.complete();
             });
         }).catch((err) => {
             console.log('error starting NFC scan', err);
             this.presentToast(`Error: ${err}`);
             this.isListening = false;
             observer.error(err);
         });
     }*/

    private readNfcIOS(observer) {
        this.isListening = true;

        this.nfc.scanNdef().then((tag) => {
            this.isListening = false;
            console.log(JSON.stringify(tag));

            // Check if NDEF messages are available
            if (tag && tag.ndefMessage && tag.ndefMessage.length > 0) {
                // Assuming the first message in the array contains your JSON data
                const payload = tag.ndefMessage[0].payload;

                // Assuming the status byte and language code ('en') are always present and followed by a JSON string,
                // find the start index of the JSON object, which is the first occurrence of '{'
                const startIndex = payload.indexOf(123); // ASCII value of '{' is 123

                // Find the end index of the JSON object, which is the last occurrence of '}'
                const endIndex = payload.lastIndexOf(125); // ASCII value of '}' is 125

                // Extract the JSON part of the payload and convert it to a string
                const jsonString = String.fromCharCode(...payload.slice(startIndex, endIndex + 1));

                this.presentToast(`Tag Scanned, DATA: ${jsonString}`);
                observer.next({
                    payload: jsonString
                });
            } else {
                this.presentToast('No NDEF message found on the tag');
                observer.error('No NDEF message found');
            }
            observer.complete();

        }).catch((err) => {
            console.log('error starting NFC scan', err);
            this.presentToast(`Error: ${err}`);
            this.isListening = false;
            observer.error(err);
        });
    }

    execute(reqOpts ? : any) {
        return new Observable((observer) => {
            try {
                (async () => {
                    let srvName = await this.Apperyio.getGSNameByImpl(this);
                    if (!srvName) {
                        this.presentToast("Service was not found");
                        observer.error("Service was not found");
                        return;
                    }
                    let service = this.entityAPI.get(srvName),
                        echo = service.echo;
                    if (typeof echo === 'undefined') {
                        if (( < any > window).cordova) {
                            if (this.platform.is('ios')) {
                                this.readNfcIOS(observer);
                            } else {
                                this.readNfcAndroid(observer);
                            }
                        } else {
                            this.presentToast('Cordova not available - this device does not support NFC or the app is running in a browser.');
                            observer.error('Cordova not available - this device does not support NFC or the app is running in a browser.');
                        }
                    } else {
                        try {
                            echo = JSON.parse(echo);
                        } catch (e) {}
                        // Send the default Echo Message for an NFC Tag
                        observer.next({
                            "message": [{
                                    "tnf": 1,
                                    "type": "T", // 'T' for text
                                    "id": [1, 2, 3, 4],
                                    "payload": [
                                        // Status byte: 0x02 (UTF-8 encoding and 2-byte language code)
                                        // Language code: 'en' (101, 110 in ASCII)
                                        // Text: 'Hello World' in ASCII
                                        2, 101, 110, 72, 101, 108, 108, 111, 32, 87, 111, 114, 108, 100
                                    ]
                                },
                                {
                                    "tnf": 1,
                                    "type": "U", // 'U' for URI
                                    "id": [5, 6, 7, 8],
                                    "payload": [
                                        // URI Identifier Code: 0x01 (http://www.)
                                        // URI: 'example.com' in ASCII
                                        1, 101, 120, 97, 109, 112, 108, 101, 46, 99, 111, 109
                                    ]
                                },
                                {
                                    "tnf": 1,
                                    "type": "T", // Another 'T' for text
                                    "id": [9, 10, 11, 12],
                                    "payload": [
                                        // Status byte: 0x02 (UTF-8 encoding and 2-byte language code)
                                        // Language code: 'es' (101, 115 in ASCII)
                                        // Text: 'Hola Mundo' in ASCII
                                        2, 101, 115, 72, 111, 108, 97, 32, 77, 117, 110, 100, 111
                                    ]
                                }
                            ],
                            "textRecords": [{
                                    "tnf": 1,
                                    "type": "T", // 'T' for text
                                    "id": [1, 2, 3, 4],
                                    "payload": [
                                        2, 101, 110, 72, 101, 108, 108, 111, 32, 87, 111, 114, 108, 100
                                    ]
                                },
                                {
                                    "tnf": 1,
                                    "type": "T", // Another 'T' for text
                                    "id": [9, 10, 11, 12],
                                    "payload": [
                                        2, 101, 115, 72, 111, 108, 97, 32, 77, 117, 110, 100, 111
                                    ]
                                }
                            ],
                            "texts": [
                                "Hello World",
                                "Hola Mundo"
                            ]
                        });
                        observer.complete();
                    }
                })();
            } catch (e) {
                this.presentToast(`Error: ${e}`);
                observer.error("Some error");
            }
        });
    }
}

/*
    Service class should be exported as ExportedClass
*/
export {
    NFCScannerService as ExportedClass
};