import {
    Injectable,
    NgZone
} from '@angular/core';
import _ from "lodash";
import {
    Observable
} from "rxjs";
import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';
import {
    EntityApiService
} from '../apperyio/apperyio';
import {
    HttpClient,
    HttpParams
} from '@angular/common/http';

@Injectable()
class GoogleLogoutService {

    window: any = window;

    constructor(private entityAPI: EntityApiService, private Apperyio: ApperyioHelperService, private http: HttpClient, private ngZone: NgZone) {}
    execute(reqOpts ? : any) {
        return new Observable((observer) => {
            try {
                (async() => {
                    let srvName = await this.Apperyio.getGSNameByImpl(this);
                    if (!srvName) {
                        observer.error("Service was not found");
                        return;
                    }
                    let service = this.entityAPI.get(srvName),
                        echo = service.echo,
                        defaults = service.request.data,
                        request,
                        response;
                    if (_.isUndefined(echo)) {
                        /**
                         * Passed request data structure IS RECOMMENDED to match the default request, described in service!
                         * An example of how to merge default request data with passed data (_.extend() should be used with flat objects only):
                         */
                        request = _.extend({}, defaults, reqOpts);


                        if (this.window.cordova) {
                            this.window.plugins.googleplus.logout(
                                (message) => {
                                    console.log(message);

                                    this.ngZone.run(() => {
                                        observer.next({
                                            status: "success",
                                            message: message
                                        });
                                        observer.complete();
                                    });
                                }
                            );
                        } else {
                            observer.next({
                                status: "success",
                                message: "Logged user out" 
                            });
                            observer.complete();
                        }
                    } else {
                        try {
                            echo = JSON.parse(echo);
                        } catch (e) {
                            console.log('error', e);
                            observer.error(e);

                        }

                        observer.next(echo);
                        observer.complete();
                    }
                })()
            } catch (e) {
                console.log('error', e);
                observer.error(e);

            }
        });
    }
}

/*
    Service class should be exported as ExportedClass
*/
export {
    GoogleLogoutService as ExportedClass
};