import {
    NgModule
} from '@angular/core';
import {
    BrowserModule
} from '@angular/platform-browser';
import {
    BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
    FormsModule
} from '@angular/forms';
import {
    HttpClientModule
} from '@angular/common/http';
import {
    IonicModule
} from '@ionic/angular';
import {
    IonicStorageModule
} from '@ionic/storage';
import {
    ApperyioModule
} from './scripts/apperyio/apperyio.module';
import {
    ApperyioDeclarablesModule
} from './scripts/apperyio/declarables/apperyio.declarables.module';
import {
    PipesModule
} from './scripts/pipes.module';
import {
    DirectivesModule
} from './scripts/directives.module';
import {
    ComponentsModule
} from './scripts/components.module';
import {
    CustomComponentsModule
} from './scripts/custom-components.module';
import {
    CustomModulesModule
} from './scripts/custom-modules.module';
import {
    Sanitizer
} from '@angular/core';
import {
    NgDompurifySanitizer
} from '@tinkoff/ng-dompurify';
import {
    createTranslateLoader
} from './scripts/apperyio/translate_module';
import {
    TranslateModule
} from '@ngx-translate/core';
import {
    TranslateLoader
} from '@ngx-translate/core';
import {
    HttpClient
} from '@angular/common/http';
import {
    app
} from './app';
import {
    AppRoutingModule
} from './app-routing.module';
import {
    InitialSetup
} from './InitialSetup/InitialSetup';
import {
    CropImage
} from './CropImage/CropImage';
import {
    Note
} from './Note/Note';
import {
    ExportedClass as BarcodeScannerService
} from './scripts/custom/BarcodeScanner_Impl';
import {
    ExportedClass as GoogleLogoutService
} from './scripts/custom/GoogleLogoutService_Impl';
import {
    ExportedClass as NFCScannerService
} from './scripts/custom/NFCScannerService_Impl';
import {
    ExportedClass as GoogleLoginService
} from './scripts/custom/GoogleLoginService_Impl';
import {
    ExportedClass as LoadUserInstallationsLocal_service
} from './scripts/custom/LoadUserInstallationsLocal_service_Impl';
import {
    ExportedClass as Sentinels_batch_update_service
} from './scripts/services/Sentinels_batch_update_service';
import {
    ExportedClass as Sentinels_update_service
} from './scripts/services/Sentinels_update_service';
import {
    ExportedClass as Sentinels_read_service
} from './scripts/services/Sentinels_read_service';
import {
    ExportedClass as Sentinels_create_service
} from './scripts/services/Sentinels_create_service';
import {
    ExportedClass as Sentinels_distinct_service
} from './scripts/services/Sentinels_distinct_service';
import {
    ExportedClass as Sentinels_query_service
} from './scripts/services/Sentinels_query_service';
import {
    ExportedClass as Sentinels_list_service
} from './scripts/services/Sentinels_list_service';
import {
    ExportedClass as Sentinels_delete_service
} from './scripts/services/Sentinels_delete_service';
import {
    ExportedClass as Installations_read_service
} from './scripts/services/Installations_read_service';
import {
    ExportedClass as Installations_create_service
} from './scripts/services/Installations_create_service';
import {
    ExportedClass as Installations_query_service
} from './scripts/services/Installations_query_service';
import {
    ExportedClass as Installations_list_service
} from './scripts/services/Installations_list_service';
import {
    ExportedClass as Installations_delete_service
} from './scripts/services/Installations_delete_service';
import {
    ExportedClass as Installations_update_service
} from './scripts/services/Installations_update_service';
import {
    ExportedClass as Installations_batch_update_service
} from './scripts/services/Installations_batch_update_service';
import {
    ExportedClass as Installations_distinct_service
} from './scripts/services/Installations_distinct_service';
import {
    ExportedClass as Sentinel_Sensor_Data_distinct_service
} from './scripts/services/Sentinel_Sensor_Data_distinct_service';
import {
    ExportedClass as Sentinel_Sensor_Data_query_service
} from './scripts/services/Sentinel_Sensor_Data_query_service';
import {
    ExportedClass as Sentinel_Sensor_Data_list_service
} from './scripts/services/Sentinel_Sensor_Data_list_service';
import {
    ExportedClass as Sentinel_Sensor_Data_read_service
} from './scripts/services/Sentinel_Sensor_Data_read_service';
import {
    ExportedClass as Profiles_read_service
} from './scripts/services/Profiles_read_service';
import {
    ExportedClass as login_service
} from './scripts/services/login_service';
import {
    ExportedClass as Sentinel_Sensor_Data_create_service
} from './scripts/services/Sentinel_Sensor_Data_create_service';
import {
    ExportedClass as Carbon_Credits_query_service
} from './scripts/services/Carbon_Credits_query_service';
import {
    ExportedClass as Carbon_Credits_list_service
} from './scripts/services/Carbon_Credits_list_service';
import {
    ExportedClass as Credits_read_service
} from './scripts/services/Credits_read_service';
import {
    ExportedClass as logout_service
} from './scripts/services/logout_service';
import {
    ExportedClass as GenericServiceLoadUserInstallationFromStorage_Impl
} from './scripts/custom/GenericServiceLoadUserInstallationFromStorage_Impl';
import {
    ExportedClass as spinnerManager
} from './scripts/custom/spinnerManager';
import {
    BarcodeScanner
} from '@ionic-native/barcode-scanner/ngx';
import {
    File
} from '@ionic-native/file/ngx';
import {
    WebView
} from '@ionic-native/ionic-webview/ngx';
import {
    Device
} from '@ionic-native/device/ngx';
import {
    SplashScreen
} from '@ionic-native/splash-screen/ngx';
import {
    StatusBar
} from '@ionic-native/status-bar/ngx';
import {
    Keyboard
} from '@ionic-native/keyboard/ngx';
import {
    Network
} from '@ionic-native/network/ngx';
import {
    SecureStorage
} from '@ionic-native/secure-storage/ngx';
import {
    NFC,
    Ndef
} from '@ionic-native/nfc/ngx';
import {
    Vibration
} from '@ionic-native/vibration/ngx';
( < any > NgDompurifySanitizer.prototype)._sanitize_fn = NgDompurifySanitizer.prototype.sanitize;
NgDompurifySanitizer.prototype.sanitize = function(...args) {
    let value: any = args[1];
    if (value && value.hasOwnProperty("changingThisBreaksApplicationSecurity")) {
        args[1] = value.changingThisBreaksApplicationSecurity
    }
    return this._sanitize_fn(...args);
}
var getIonicModuleConfig, getIonicStorageModuleConfig;
@NgModule({
    declarations: [
        app, InitialSetup, CropImage, Note
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        IonicModule.forRoot((typeof getIonicModuleConfig === "function")? getIonicModuleConfig(): undefined),
        HttpClientModule,
        ApperyioModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        ApperyioDeclarablesModule,
        CustomComponentsModule,
        CustomModulesModule,
        IonicStorageModule.forRoot((typeof getIonicStorageModuleConfig === "function")? getIonicStorageModuleConfig(): undefined),
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    bootstrap: [
        app
    ],
    entryComponents: [
        //app
        InitialSetup, CropImage, Note
    ],
    providers: [
        StatusBar,
        SplashScreen,
        WebView,
        Device,
        Keyboard,
        Network,
        SecureStorage,
        {
            provide: Sanitizer,
            useClass: NgDompurifySanitizer,
        },
        BarcodeScanner,
        File,
        BarcodeScannerService,
        GoogleLogoutService,
        NFCScannerService,
        GoogleLoginService,
        LoadUserInstallationsLocal_service,
        Sentinels_batch_update_service,
        Sentinels_update_service,
        Sentinels_read_service,
        Sentinels_create_service,
        Sentinels_distinct_service,
        Sentinels_query_service,
        Sentinels_list_service,
        Sentinels_delete_service,
        Installations_read_service,
        Installations_create_service,
        Installations_query_service,
        Installations_list_service,
        Installations_delete_service,
        Installations_update_service,
        Installations_batch_update_service,
        Installations_distinct_service,
        Sentinel_Sensor_Data_distinct_service,
        Sentinel_Sensor_Data_query_service,
        Sentinel_Sensor_Data_list_service,
        Sentinel_Sensor_Data_read_service,
        Profiles_read_service,
        login_service,
        Sentinel_Sensor_Data_create_service,
        Carbon_Credits_query_service,
        Carbon_Credits_list_service,
        Credits_read_service,
        logout_service,
        GenericServiceLoadUserInstallationFromStorage_Impl,
        spinnerManager,
        NFC, Ndef, Vibration
    ]
})
export class AppModule {}