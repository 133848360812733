import { Injectable } from '@angular/core';
import { ApperyioHelperService } from '../apperyio/apperyio_helper';

@Injectable()
export class SpinnerManager {
    private $a: ApperyioHelperService;
    private $v: any;

    constructor(private Apperyio: ApperyioHelperService) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
    }

    showSpinner(): void {
        document.getElementById('Spinner3').style.display = 'block';
    }

    hideSpinner(): void {
        document.getElementById('Spinner3').style.display = 'none';
    }
}

export { SpinnerManager as ExportedClass };
