import {
    NgModule
} from '@angular/core';
import {
    CommonModule
} from '@angular/common';
import {
    ImageCropperModule
} from 'ngx-image-cropper';
import {
    IonicSelectableModule
} from 'ionic-selectable';
import {
    SimpleMaskModule
} from 'ngx-ion-simple-mask';
import { CodeInputModule } from 'angular-code-input';
/**
 *
 * See https://angular.io/guide/sharing-ngmodules, https://angular.io/api/core/NgModule for more info on Angular Modules.
 */
@NgModule({
    imports: [
        CommonModule,
        IonicSelectableModule,
        SimpleMaskModule,
        CodeInputModule
    ],
    declarations: [],
    exports: [
        ImageCropperModule,
        IonicSelectableModule,
        SimpleMaskModule,
        CodeInputModule
    ]
})

class CropImageModuleModule {}

export {
    CropImageModuleModule as ExportedClass
};