import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'Note.html',
    selector: 'page-note',
    styleUrls: ['Note.css', 'Note.scss']
})
export class Note {
    public item: any;
    public originalItem: any;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    @ViewChild('itemForm', {
        static: true
    }) public itemForm;
    async ionViewWillEnter(): Promise < any > {
        this.originalItem = _.cloneDeep(this.item);
        window.currentScreen = this;
    }
    async save(): Promise < any > {
        if (this.$a.markFormAsTouched(this.itemForm)) return;
        let data = await this.$a.db.save('Notes', { ...this.item,
            userId: this.$v.userId
        }, {
            loading: true,
            message: 'Note was saved'
        });
        this.$a.closeModal(data);
    }
    async close(): Promise < any > {
        if (!_.isEqual(this.originalItem, this.item)) {
            await this.$a.alertOkCancel("Are you sure?", "Unsaved data will be lost", async () => this.$a.closeModal(null));
        } else {
            this.$a.closeModal(null);
        }
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.item = {};
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
}