/**
 * Models generated from "Model and Storage" and models extracted from services.
 * To generate entity use syntax:
 * Apperyio.EntityAPI("<model_name>[.<model_field>]");
 */
export var models = {
    "Sentinel": {
        "type": "object",
        "properties": {
            "CommissionDate": {
                "type": "string"
            },
            "Status": {
                "type": "string"
            },
            "_createdAt": {
                "type": "string"
            },
            "gatewayDeviceID": {
                "type": "object",
                "properties": {
                    "lastCheckIn": {
                        "type": "string"
                    },
                    "gatewayName": {
                        "type": "string"
                    },
                    "gatewaySentinels": {
                        "type": "array",
                        "items": [{
                            "type": "any"
                        }]
                    },
                    "ipAddress": {
                        "type": "string"
                    },
                    "commissionDate": {
                        "type": "string"
                    },
                    "_createdAt": {
                        "type": "string"
                    },
                    "acl": {
                        "type": "object",
                        "properties": {}
                    },
                    "_updatedAt": {
                        "type": "string"
                    },
                    "installationID": {
                        "type": "object",
                        "properties": {
                            "_updatedAt": {
                                "type": "string"
                            },
                            "LastDataReceived": {
                                "type": "string"
                            },
                            "acl": {
                                "type": "object",
                                "properties": {}
                            },
                            "TotalDevices": {
                                "type": "number"
                            },
                            "InstallationName": {
                                "type": "string"
                            },
                            "Notes": {
                                "type": "string"
                            },
                            "GPSCoordinates": {
                                "type": "array",
                                "items": [{
                                    "type": "any"
                                }, {
                                    "type": "number",
                                    "index": 0
                                }, {
                                    "type": "number",
                                    "index": 1
                                }]
                            },
                            "_createdAt": {
                                "type": "string"
                            },
                            "GatewayDeviceID": {
                                "type": "array",
                                "items": [{
                                    "type": "any"
                                }]
                            },
                            "_id": {
                                "type": "string"
                            },
                            "Location": {
                                "type": "string"
                            }
                        }
                    },
                    "_id": {
                        "type": "string"
                    },
                    "fwVersion": {
                        "type": "string"
                    }
                }
            },
            "DeviceName": {
                "type": "string"
            },
            "installationID": {
                "type": "object",
                "properties": {
                    "GatewayDeviceID": {
                        "type": "array",
                        "items": [{
                            "type": "any"
                        }]
                    },
                    "_id": {
                        "type": "string"
                    },
                    "_updatedAt": {
                        "type": "string"
                    },
                    "InstallationName": {
                        "type": "string"
                    },
                    "_createdAt": {
                        "type": "string"
                    },
                    "Notes": {
                        "type": "string"
                    },
                    "LastDataReceived": {
                        "type": "string"
                    },
                    "GPSCoordinates": {
                        "type": "array",
                        "items": [{
                            "type": "number",
                            "index": 0
                        }, {
                            "type": "number",
                            "index": 1
                        }, {
                            "type": "any"
                        }]
                    },
                    "Location": {
                        "type": "string"
                    },
                    "acl": {
                        "type": "object",
                        "properties": {}
                    },
                    "TotalDevices": {
                        "type": "number"
                    }
                }
            },
            "acl": {
                "type": "object",
                "properties": {}
            },
            "DeviceID": {
                "type": "string"
            },
            "LastTransmission": {
                "type": "string"
            },
            "SerialNumber": {
                "type": "string"
            },
            "_id": {
                "type": "string"
            },
            "_updatedAt": {
                "type": "string"
            },
            "BatteryLevel": {
                "type": "number"
            },
            "Installation": {
                "type": "string"
            }
        }
    },
    "SentinelSensorDataCollection": {
        "type": "array",
        "items": [{
            "type": "SentinelSensorData"
        }]
    },
    "SlidesModel": {
        "type": "array",
        "items": [{
            "type": "SlideModel"
        }]
    },
    "SlideModel": {
        "type": "object",
        "properties": {
            "title": {
                "type": "string"
            },
            "text": {
                "type": "string"
            },
            "img": {
                "type": "string"
            }
        }
    },
    "Any": {
        "type": "any"
    },
    "Function": {
        "type": "Function"
    },
    "Promise": {
        "type": "Promise"
    },
    "Observable": {
        "type": "Observable"
    },
    "chartData": {
        "type": "array",
        "items": [{
            "type": "number"
        }]
    },
    "String": {
        "type": "string"
    },
    "TextRecord": {
        "type": "object",
        "properties": {
            "tnf": {
                "type": "number"
            },
            "type": {
                "type": "string"
            },
            "id": {
                "type": "array",
                "items": [{
                    "type": "number"
                }]
            },
            "payload": {
                "type": "array",
                "items": [{
                    "type": "number"
                }]
            }
        }
    },
    "Boolean": {
        "type": "boolean"
    },
    "Installations": {
        "type": "array",
        "items": [{
            "type": "Installation"
        }]
    },
    "Number": {
        "type": "number"
    },
    "SentinelSensorData": {
        "type": "object",
        "properties": {
            "Signature": {
                "type": "string"
            },
            "Irrigation": {
                "type": "number"
            },
            "Temperature": {
                "type": "number"
            },
            "Stress": {
                "type": "string"
            },
            "_createdAt": {
                "type": "string"
            },
            "_updatedAt": {
                "type": "string"
            },
            "Humidity": {
                "type": "number"
            },
            "sentinelID": {
                "type": "object",
                "properties": {
                    "DeviceName": {
                        "type": "string"
                    },
                    "BatteryLevel": {
                        "type": "number"
                    },
                    "_id": {
                        "type": "string"
                    },
                    "_createdAt": {
                        "type": "string"
                    },
                    "Status": {
                        "type": "string"
                    },
                    "_updatedAt": {
                        "type": "string"
                    },
                    "LastTransmission": {
                        "type": "string"
                    },
                    "SerialNumber": {
                        "type": "string"
                    },
                    "installationID": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string"
                            },
                            "collName": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "Growth": {
                "type": "number"
            },
            "_id": {
                "type": "string"
            }
        }
    },
    "Installation": {
        "type": "object",
        "properties": {
            "_id": {
                "type": "string"
            },
            "acl": {
                "type": "object",
                "properties": {
                    "userId": {
                        "type": "object",
                        "properties": {
                            "read": {
                                "type": "boolean"
                            },
                            "write": {
                                "type": "boolean"
                            }
                        }
                    }
                }
            },
            "Notes": {
                "type": "string"
            },
            "Location": {
                "type": "string"
            },
            "LastDataReceived": {
                "type": "string"
            },
            "GatewayDeviceID": {
                "type": "array",
                "items": [{
                    "type": "string"
                }]
            },
            "_updatedAt": {
                "type": "string"
            },
            "GPSCoordinates": {
                "type": "array",
                "items": [{
                    "type": "number"
                }]
            },
            "InstallationName": {
                "type": "string"
            },
            "TotalDevices": {
                "type": "number"
            }
        }
    },
    "NFCResponseData": {
        "type": "object",
        "properties": {
            "message": {
                "type": "array",
                "items": [{
                    "type": "TextRecord"
                }]
            },
            "textRecords": {
                "type": "array",
                "items": [{
                    "type": "TextRecord"
                }]
            },
            "texts": {
                "type": "array",
                "items": [{
                    "type": "string"
                }]
            }
        }
    },
    "Sentinels": {
        "type": "array",
        "items": [{
            "type": "object",
            "properties": {
                "SerialNumber": {
                    "type": "string"
                },
                "BatteryLevel": {
                    "type": "number"
                },
                "LastTransmission": {
                    "type": "string"
                },
                "_id": {
                    "type": "string"
                },
                "_updatedAt": {
                    "type": "string"
                },
                "DeviceName": {
                    "type": "string"
                },
                "acl": {
                    "type": "object",
                    "properties": {}
                },
                "gatewayDeviceID": {
                    "type": "object",
                    "properties": {
                        "commissionDate": {
                            "type": "string"
                        },
                        "ipAddress": {
                            "type": "string"
                        },
                        "gatewayName": {
                            "type": "string"
                        },
                        "fwVersion": {
                            "type": "string"
                        },
                        "_id": {
                            "type": "string"
                        },
                        "_updatedAt": {
                            "type": "string"
                        },
                        "_createdAt": {
                            "type": "string"
                        },
                        "gatewaySentinels": {
                            "type": "array",
                            "items": [{
                                "type": "any"
                            }]
                        },
                        "acl": {
                            "type": "object",
                            "properties": {}
                        },
                        "installationID": {
                            "type": "object",
                            "properties": {
                                "_id": {
                                    "type": "string"
                                },
                                "GPSCoordinates": {
                                    "type": "array",
                                    "items": [{
                                        "type": "any"
                                    }, {
                                        "type": "number",
                                        "index": 0
                                    }, {
                                        "type": "number",
                                        "index": 1
                                    }]
                                },
                                "LastDataReceived": {
                                    "type": "string"
                                },
                                "_updatedAt": {
                                    "type": "string"
                                },
                                "acl": {
                                    "type": "object",
                                    "properties": {}
                                },
                                "GatewayDeviceID": {
                                    "type": "array",
                                    "items": [{
                                        "type": "any"
                                    }]
                                },
                                "InstallationName": {
                                    "type": "string"
                                },
                                "_createdAt": {
                                    "type": "string"
                                },
                                "Notes": {
                                    "type": "string"
                                },
                                "TotalDevices": {
                                    "type": "number"
                                },
                                "Location": {
                                    "type": "string"
                                }
                            }
                        },
                        "lastCheckIn": {
                            "type": "string"
                        }
                    }
                },
                "_createdAt": {
                    "type": "string"
                },
                "installationID": {
                    "type": "object",
                    "properties": {
                        "_updatedAt": {
                            "type": "string"
                        },
                        "GatewayDeviceID": {
                            "type": "array",
                            "items": [{
                                "type": "any"
                            }]
                        },
                        "Notes": {
                            "type": "string"
                        },
                        "GPSCoordinates": {
                            "type": "array",
                            "items": [{
                                "type": "number",
                                "index": 1
                            }, {
                                "type": "number",
                                "index": 0
                            }, {
                                "type": "any"
                            }]
                        },
                        "_id": {
                            "type": "string"
                        },
                        "TotalDevices": {
                            "type": "number"
                        },
                        "Location": {
                            "type": "string"
                        },
                        "InstallationName": {
                            "type": "string"
                        },
                        "LastDataReceived": {
                            "type": "string"
                        },
                        "acl": {
                            "type": "object",
                            "properties": {}
                        },
                        "_createdAt": {
                            "type": "string"
                        }
                    }
                },
                "Status": {
                    "type": "string"
                }
            }
        }]
    },
    "Profiles_read_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Profiles/{_id}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "_updatedAt": {
                                        "type": "string"
                                    },
                                    "_id": {
                                        "type": "string"
                                    },
                                    "job": {
                                        "type": "string"
                                    },
                                    "acl": {
                                        "type": "object",
                                        "properties": {}
                                    },
                                    "firstName": {
                                        "type": "string"
                                    },
                                    "address": {
                                        "type": "string"
                                    },
                                    "userInstallations": {
                                        "type": "array",
                                        "items": [{
                                            "type": "null"
                                        }]
                                    },
                                    "userId": {
                                        "type": "string"
                                    },
                                    "lastName": {
                                        "type": "string"
                                    },
                                    "avatar": {
                                        "type": "object",
                                        "properties": {
                                            "fileName": {
                                                "type": "string"
                                            },
                                            "fileurl": {
                                                "type": "string"
                                            },
                                            "originalFileName": {
                                                "type": "string"
                                            }
                                        }
                                    },
                                    "phone": {
                                        "type": "string"
                                    },
                                    "_createdAt": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "logout_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/logout"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Installations_read_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Installations/{_id}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "InstallationID": {
                                        "type": "string",
                                        "default": "1"
                                    },
                                    "GPSCoordinates": {
                                        "type": "array",
                                        "items": [{
                                            "type": "number",
                                            "default": -114.08529
                                        }]
                                    },
                                    "InstallationName": {
                                        "type": "string",
                                        "default": "Greenwood Oasis"
                                    },
                                    "GatewayDeviceID": {
                                        "type": "string",
                                        "default": "6g9e07b3b5e5693b8d8d8d8a"
                                    },
                                    "LastDataReceived": {
                                        "type": "string",
                                        "default": "2023-10-15 12:45:32.000"
                                    },
                                    "Location": {
                                        "type": "string",
                                        "default": "Northern Forest"
                                    },
                                    "_updatedAt": {
                                        "type": "string",
                                        "default": "2023-11-02T15:18:18.229Z"
                                    },
                                    "_id": {
                                        "type": "string",
                                        "default": "654046182e22d731e979375b"
                                    },
                                    "TotalDevices": {
                                        "type": "string",
                                        "default": "250"
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Installations_create_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Installations"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "Location": {
                                "type": "string"
                            },
                            "GatewayDeviceID": {
                                "type": "array",
                                "items": [{
                                    "type": "null"
                                }]
                            },
                            "GPSCoordinates": {
                                "type": "array",
                                "items": [{
                                    "type": "number",
                                    "default": null,
                                    "index": 0
                                }, {
                                    "type": "number",
                                    "default": null,
                                    "index": 1
                                }, {
                                    "type": "null"
                                }]
                            },
                            "InstallationName": {
                                "type": "string"
                            },
                            "TotalDevices": {
                                "type": "number",
                                "default": null
                            },
                            "LastDataReceived": {
                                "type": "string"
                            },
                            "Notes": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "full_object": {
                                "type": "string",
                                "default": "false"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "_createdAt": {
                                "type": "string"
                            },
                            "_id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Installations_query_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Installations/query"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "count": {
                                "type": "boolean",
                                "default": null
                            },
                            "sort": {
                                "type": "string"
                            },
                            "limit": {
                                "type": "number",
                                "default": null
                            },
                            "skip": {
                                "type": "number",
                                "default": null
                            },
                            "proj": {
                                "type": "object",
                                "properties": {}
                            },
                            "where": {
                                "type": "object",
                                "properties": {
                                    "GatewayDeviceID": {
                                        "type": "string"
                                    },
                                    "LastDataReceived": {
                                        "type": "string"
                                    },
                                    "InstallationName": {
                                        "type": "string"
                                    },
                                    "InstallationID": {
                                        "type": "string"
                                    },
                                    "TotalDevices": {
                                        "type": "string"
                                    },
                                    "Location": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "array",
                        "items": [{
                            "type": "object",
                            "properties": {
                                "acl": {
                                    "type": "object",
                                    "properties": {}
                                },
                                "TotalDevices": {
                                    "type": "string"
                                },
                                "_id": {
                                    "type": "string"
                                },
                                "_updatedAt": {
                                    "type": "string"
                                },
                                "_createdAt": {
                                    "type": "string"
                                },
                                "InstallationName": {
                                    "type": "string"
                                },
                                "Location": {
                                    "type": "string"
                                },
                                "LastDataReceived": {
                                    "type": "string"
                                },
                                "InstallationID": {
                                    "type": "string"
                                },
                                "GatewayDeviceID": {
                                    "type": "string"
                                }
                            }
                        }]
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Installations_list_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Installations"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "limit": {
                                "type": "string"
                            },
                            "where": {
                                "type": "string"
                            },
                            "skip": {
                                "type": "string"
                            },
                            "count": {
                                "type": "string"
                            },
                            "proj": {
                                "type": "string"
                            },
                            "sort": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "Notes": {
                                            "type": "string",
                                            "default": "123"
                                        },
                                        "TotalDevices": {
                                            "type": "number",
                                            "default": 28
                                        },
                                        "acl": {
                                            "type": "object",
                                            "properties": {
                                                "userId": {
                                                    "type": "object",
                                                    "properties": {
                                                        "write": {
                                                            "type": "boolean",
                                                            "default": true
                                                        },
                                                        "read": {
                                                            "type": "boolean",
                                                            "default": true
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "GatewayDeviceID": {
                                            "type": "array",
                                            "items": [{
                                                "type": "string",
                                                "default": "6g9e07b3b5e5693b8d8d8d8c"
                                            }]
                                        },
                                        "InstallationName": {
                                            "type": "string",
                                            "default": "Emerald Enclave"
                                        },
                                        "GPSCoordinates": {
                                            "type": "array",
                                            "items": [{
                                                "type": "number",
                                                "default": -94.25577
                                            }]
                                        },
                                        "_updatedAt": {
                                            "type": "string",
                                            "default": "2023-11-08T19:15:57.935Z"
                                        },
                                        "LastDataReceived": {
                                            "type": "string",
                                            "default": "2023-11-06T00:00:00.000Z"
                                        },
                                        "Location": {
                                            "type": "string",
                                            "default": "Southern Plains"
                                        },
                                        "_id": {
                                            "type": "string",
                                            "default": "654046182e22d731e979375d"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Installations_delete_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Installations/{_id}"
            },
            "method": {
                "type": "string",
                "default": "delete"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Installations_update_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Installations/{_id}"
            },
            "method": {
                "type": "string",
                "default": "put"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "Location": {
                                "type": "string"
                            },
                            "InstallationID": {
                                "type": "string"
                            },
                            "InstallationName": {
                                "type": "string"
                            },
                            "GatewayDeviceID": {
                                "type": "string"
                            },
                            "LastDataReceived": {
                                "type": "string"
                            },
                            "TotalDevices": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "full_object": {
                                "type": "string",
                                "default": "false"
                            },
                            "_id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "_updatedAt": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "login_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/login"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "username": {
                                "type": "string"
                            },
                            "password": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string"
                            },
                            "sessionToken": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinels_batch_update_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinels"
            },
            "method": {
                "type": "string",
                "default": "put"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "update": {
                                "type": "object",
                                "properties": {
                                    "Status": {
                                        "type": "string"
                                    },
                                    "BatteryLevel": {
                                        "type": "string"
                                    },
                                    "Installation": {
                                        "type": "string"
                                    },
                                    "LastTransmission": {
                                        "type": "string"
                                    },
                                    "DeviceName": {
                                        "type": "string"
                                    },
                                    "DeviceID": {
                                        "type": "string"
                                    }
                                }
                            },
                            "where": {
                                "type": "object",
                                "properties": {
                                    "Installation": {
                                        "type": "string"
                                    },
                                    "BatteryLevel": {
                                        "type": "string"
                                    },
                                    "DeviceName": {
                                        "type": "string"
                                    },
                                    "Status": {
                                        "type": "string"
                                    },
                                    "LastTransmission": {
                                        "type": "string"
                                    },
                                    "DeviceID": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "_updatedAt": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Carbon_Credits_query_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Carbon_Credits/query"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "sort": {
                                "type": "string"
                            },
                            "count": {
                                "type": "boolean",
                                "default": null
                            },
                            "limit": {
                                "type": "number",
                                "default": null
                            },
                            "where": {
                                "type": "object",
                                "properties": {
                                    "installationID._id": {
                                        "type": "object",
                                        "properties": {}
                                    },
                                    "dateCreated": {
                                        "type": "string"
                                    },
                                    "amountKg": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "dataBlob": {
                                        "type": "array",
                                        "items": [{
                                            "type": "null"
                                        }]
                                    },
                                    "userID": {
                                        "type": "object",
                                        "properties": {}
                                    },
                                    "IPFSDataBlob": {
                                        "type": "string"
                                    }
                                }
                            },
                            "skip": {
                                "type": "number",
                                "default": null
                            },
                            "proj": {
                                "type": "object",
                                "properties": {}
                            },
                            "include": {
                                "type": "string",
                                "default": "userID,installationID"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "array",
                        "items": [{
                            "type": "object",
                            "properties": {
                                "_id": {
                                    "type": "string"
                                },
                                "installationID": {
                                    "type": "object",
                                    "properties": {
                                        "GatewayDeviceID": {
                                            "type": "array",
                                            "items": [{
                                                "type": "null"
                                            }]
                                        },
                                        "LastDataReceived": {
                                            "type": "string"
                                        },
                                        "Location": {
                                            "type": "string"
                                        },
                                        "GPSCoordinates": {
                                            "type": "array",
                                            "items": [{
                                                "type": "number",
                                                "default": null,
                                                "index": 0
                                            }, {
                                                "type": "null"
                                            }, {
                                                "type": "number",
                                                "default": null,
                                                "index": 1
                                            }]
                                        },
                                        "TotalDevices": {
                                            "type": "number",
                                            "default": null
                                        },
                                        "InstallationName": {
                                            "type": "string"
                                        },
                                        "_id": {
                                            "type": "string"
                                        },
                                        "acl": {
                                            "type": "object",
                                            "properties": {}
                                        },
                                        "_createdAt": {
                                            "type": "string"
                                        },
                                        "_updatedAt": {
                                            "type": "string"
                                        },
                                        "Notes": {
                                            "type": "string"
                                        }
                                    }
                                },
                                "IPFSDataBlob": {
                                    "type": "string"
                                },
                                "dateCreated": {
                                    "type": "string"
                                },
                                "_updatedAt": {
                                    "type": "string"
                                },
                                "_createdAt": {
                                    "type": "string"
                                },
                                "userID": {
                                    "type": "object",
                                    "properties": {
                                        "acl": {
                                            "type": "object",
                                            "properties": {}
                                        },
                                        "_id": {
                                            "type": "string"
                                        },
                                        "status": {
                                            "type": "string"
                                        },
                                        "username": {
                                            "type": "string"
                                        },
                                        "onboardingComplete": {
                                            "type": "boolean",
                                            "default": null
                                        },
                                        "code": {
                                            "type": "string"
                                        },
                                        "_updatedAt": {
                                            "type": "string"
                                        },
                                        "_createdAt": {
                                            "type": "string"
                                        }
                                    }
                                },
                                "amountKg": {
                                    "type": "number",
                                    "default": null
                                },
                                "dataBlob": {
                                    "type": "array",
                                    "items": [{
                                        "type": "null"
                                    }]
                                },
                                "acl": {
                                    "type": "object",
                                    "properties": {}
                                }
                            }
                        }]
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Carbon_Credits_list_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Carbon_Credits"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "count": {
                                "type": "string"
                            },
                            "proj": {
                                "type": "string"
                            },
                            "limit": {
                                "type": "string"
                            },
                            "sort": {
                                "type": "string"
                            },
                            "where": {
                                "type": "string"
                            },
                            "skip": {
                                "type": "string"
                            },
                            "include": {
                                "type": "string",
                                "default": "userID,installationID"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "amountKg": {
                                            "type": "number",
                                            "default": null
                                        },
                                        "IPFSDataBlob": {
                                            "type": "string"
                                        },
                                        "_id": {
                                            "type": "string"
                                        },
                                        "_updatedAt": {
                                            "type": "string"
                                        },
                                        "_createdAt": {
                                            "type": "string"
                                        },
                                        "dataBlob": {
                                            "type": "array",
                                            "items": [{
                                                "type": "null"
                                            }]
                                        },
                                        "acl": {
                                            "type": "object",
                                            "properties": {}
                                        },
                                        "dateCreated": {
                                            "type": "string"
                                        },
                                        "userID": {
                                            "type": "object",
                                            "properties": {
                                                "username": {
                                                    "type": "string"
                                                },
                                                "_updatedAt": {
                                                    "type": "string"
                                                },
                                                "code": {
                                                    "type": "string"
                                                },
                                                "_createdAt": {
                                                    "type": "string"
                                                },
                                                "acl": {
                                                    "type": "object",
                                                    "properties": {}
                                                },
                                                "onboardingComplete": {
                                                    "type": "boolean",
                                                    "default": null
                                                },
                                                "_id": {
                                                    "type": "string"
                                                },
                                                "status": {
                                                    "type": "string"
                                                }
                                            }
                                        },
                                        "installationID": {
                                            "type": "object",
                                            "properties": {
                                                "acl": {
                                                    "type": "object",
                                                    "properties": {}
                                                },
                                                "GatewayDeviceID": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "LastDataReceived": {
                                                    "type": "string"
                                                },
                                                "_id": {
                                                    "type": "string"
                                                },
                                                "Notes": {
                                                    "type": "string"
                                                },
                                                "GPSCoordinates": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }, {
                                                        "type": "number",
                                                        "default": null,
                                                        "index": 0
                                                    }, {
                                                        "type": "number",
                                                        "default": null,
                                                        "index": 1
                                                    }]
                                                },
                                                "_updatedAt": {
                                                    "type": "string"
                                                },
                                                "Location": {
                                                    "type": "string"
                                                },
                                                "InstallationName": {
                                                    "type": "string"
                                                },
                                                "TotalDevices": {
                                                    "type": "number",
                                                    "default": null
                                                },
                                                "_createdAt": {
                                                    "type": "string"
                                                }
                                            }
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Credits_read_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Carbon_Credits/{_id}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string"
                            },
                            "include": {
                                "type": "string",
                                "default": "userID,installationID"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "installationID": {
                                        "type": "object",
                                        "properties": {
                                            "InstallationName": {
                                                "type": "string"
                                            },
                                            "GPSCoordinates": {
                                                "type": "array",
                                                "items": [{
                                                    "type": "number",
                                                    "default": null,
                                                    "index": 1
                                                }, {
                                                    "type": "number",
                                                    "default": null,
                                                    "index": 0
                                                }, {
                                                    "type": "null"
                                                }]
                                            },
                                            "Notes": {
                                                "type": "string"
                                            },
                                            "Location": {
                                                "type": "string"
                                            },
                                            "_id": {
                                                "type": "string"
                                            },
                                            "GatewayDeviceID": {
                                                "type": "array",
                                                "items": [{
                                                    "type": "null"
                                                }]
                                            },
                                            "_updatedAt": {
                                                "type": "string"
                                            },
                                            "LastDataReceived": {
                                                "type": "string"
                                            },
                                            "_createdAt": {
                                                "type": "string"
                                            },
                                            "acl": {
                                                "type": "object",
                                                "properties": {}
                                            },
                                            "TotalDevices": {
                                                "type": "number",
                                                "default": null
                                            }
                                        }
                                    },
                                    "userID": {
                                        "type": "object",
                                        "properties": {
                                            "_createdAt": {
                                                "type": "string"
                                            },
                                            "username": {
                                                "type": "string"
                                            },
                                            "acl": {
                                                "type": "object",
                                                "properties": {}
                                            },
                                            "code": {
                                                "type": "string"
                                            },
                                            "status": {
                                                "type": "string"
                                            },
                                            "_id": {
                                                "type": "string"
                                            },
                                            "_updatedAt": {
                                                "type": "string"
                                            },
                                            "onboardingComplete": {
                                                "type": "boolean",
                                                "default": null
                                            }
                                        }
                                    },
                                    "dateCreated": {
                                        "type": "string"
                                    },
                                    "dataBlob": {
                                        "type": "array",
                                        "items": [{
                                            "type": "null"
                                        }]
                                    },
                                    "_id": {
                                        "type": "string"
                                    },
                                    "_createdAt": {
                                        "type": "string"
                                    },
                                    "amountKg": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "IPFSDataBlob": {
                                        "type": "string"
                                    },
                                    "_updatedAt": {
                                        "type": "string"
                                    },
                                    "acl": {
                                        "type": "object",
                                        "properties": {}
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinels_update_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinels/{_id}"
            },
            "method": {
                "type": "string",
                "default": "put"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "LastTransmission": {
                                "type": "string"
                            },
                            "DeviceID": {
                                "type": "string"
                            },
                            "BatteryLevel": {
                                "type": "string"
                            },
                            "DeviceName": {
                                "type": "string"
                            },
                            "Status": {
                                "type": "string"
                            },
                            "Installation": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string"
                            },
                            "full_object": {
                                "type": "string",
                                "default": "false"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "_updatedAt": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinels_read_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinels/{_id}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "include": {
                                "type": "string",
                                "default": "installationID"
                            },
                            "_id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "BatteryLevel": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "_updatedAt": {
                                        "type": "string"
                                    },
                                    "DeviceName": {
                                        "type": "string"
                                    },
                                    "acl": {
                                        "type": "object",
                                        "properties": {}
                                    },
                                    "installationID": {
                                        "type": "object",
                                        "properties": {
                                            "GatewayDeviceID": {
                                                "type": "array",
                                                "items": [{
                                                    "type": "null"
                                                }]
                                            },
                                            "acl": {
                                                "type": "object",
                                                "properties": {}
                                            },
                                            "InstallationName": {
                                                "type": "string"
                                            },
                                            "Notes": {
                                                "type": "string"
                                            },
                                            "Location": {
                                                "type": "string"
                                            },
                                            "LastDataReceived": {
                                                "type": "string"
                                            },
                                            "_createdAt": {
                                                "type": "string"
                                            },
                                            "_updatedAt": {
                                                "type": "string"
                                            },
                                            "_id": {
                                                "type": "string"
                                            },
                                            "TotalDevices": {
                                                "type": "number",
                                                "default": null
                                            },
                                            "GPSCoordinates": {
                                                "type": "array",
                                                "items": [{
                                                    "type": "number",
                                                    "default": null,
                                                    "index": 1
                                                }, {
                                                    "type": "number",
                                                    "default": null,
                                                    "index": 0
                                                }, {
                                                    "type": "null"
                                                }]
                                            }
                                        }
                                    },
                                    "gatewayDeviceID": {
                                        "type": "object",
                                        "properties": {
                                            "gatewayName": {
                                                "type": "string"
                                            },
                                            "gatewaySentinels": {
                                                "type": "array",
                                                "items": [{
                                                    "type": "null"
                                                }]
                                            },
                                            "commissionDate": {
                                                "type": "string"
                                            },
                                            "installationID": {
                                                "type": "object",
                                                "properties": {
                                                    "GPSCoordinates": {
                                                        "type": "array",
                                                        "items": [{
                                                            "type": "null"
                                                        }, {
                                                            "type": "number",
                                                            "default": null,
                                                            "index": 1
                                                        }, {
                                                            "type": "number",
                                                            "default": null,
                                                            "index": 0
                                                        }]
                                                    },
                                                    "Notes": {
                                                        "type": "string"
                                                    },
                                                    "GatewayDeviceID": {
                                                        "type": "array",
                                                        "items": [{
                                                            "type": "null"
                                                        }]
                                                    },
                                                    "Location": {
                                                        "type": "string"
                                                    },
                                                    "TotalDevices": {
                                                        "type": "number",
                                                        "default": null
                                                    },
                                                    "_createdAt": {
                                                        "type": "string"
                                                    },
                                                    "InstallationName": {
                                                        "type": "string"
                                                    },
                                                    "_id": {
                                                        "type": "string"
                                                    },
                                                    "LastDataReceived": {
                                                        "type": "string"
                                                    },
                                                    "_updatedAt": {
                                                        "type": "string"
                                                    },
                                                    "acl": {
                                                        "type": "object",
                                                        "properties": {}
                                                    }
                                                }
                                            },
                                            "_updatedAt": {
                                                "type": "string"
                                            },
                                            "fwVersion": {
                                                "type": "string"
                                            },
                                            "_id": {
                                                "type": "string"
                                            },
                                            "_createdAt": {
                                                "type": "string"
                                            },
                                            "lastCheckIn": {
                                                "type": "string"
                                            },
                                            "ipAddress": {
                                                "type": "string"
                                            },
                                            "acl": {
                                                "type": "object",
                                                "properties": {}
                                            }
                                        }
                                    },
                                    "_createdAt": {
                                        "type": "string"
                                    },
                                    "_id": {
                                        "type": "string"
                                    },
                                    "SerialNumber": {
                                        "type": "string"
                                    },
                                    "Status": {
                                        "type": "string"
                                    },
                                    "LastTransmission": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinels_create_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinels"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "BatteryLevel": {
                                "type": "number",
                                "default": null
                            },
                            "SerialNumber": {
                                "type": "string"
                            },
                            "DeviceName": {
                                "type": "string"
                            },
                            "Status": {
                                "type": "string"
                            },
                            "installationID": {
                                "type": "object",
                                "properties": {
                                    "collName": {
                                        "type": "string"
                                    },
                                    "_id": {
                                        "type": "string"
                                    }
                                }
                            },
                            "gatewayDeviceID": {
                                "type": "object",
                                "properties": {
                                    "_id": {
                                        "type": "string"
                                    },
                                    "collName": {
                                        "type": "string"
                                    }
                                }
                            },
                            "LastTransmission": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "full_object": {
                                "type": "string",
                                "default": "false"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "_createdAt": {
                                "type": "string"
                            },
                            "_id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Installations_batch_update_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Installations"
            },
            "method": {
                "type": "string",
                "default": "put"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "update": {
                                "type": "object",
                                "properties": {
                                    "LastDataReceived": {
                                        "type": "string"
                                    },
                                    "TotalDevices": {
                                        "type": "string"
                                    },
                                    "GatewayDeviceID": {
                                        "type": "string"
                                    },
                                    "Location": {
                                        "type": "string"
                                    },
                                    "InstallationID": {
                                        "type": "string"
                                    },
                                    "InstallationName": {
                                        "type": "string"
                                    }
                                }
                            },
                            "where": {
                                "type": "object",
                                "properties": {
                                    "Location": {
                                        "type": "string"
                                    },
                                    "TotalDevices": {
                                        "type": "string"
                                    },
                                    "LastDataReceived": {
                                        "type": "string"
                                    },
                                    "InstallationName": {
                                        "type": "string"
                                    },
                                    "InstallationID": {
                                        "type": "string"
                                    },
                                    "GatewayDeviceID": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "_updatedAt": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Installations_distinct_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Installations/distinct/{column_name}"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "where": {
                                "type": "object",
                                "properties": {
                                    "InstallationID": {
                                        "type": "string"
                                    },
                                    "TotalDevices": {
                                        "type": "string"
                                    },
                                    "GatewayDeviceID": {
                                        "type": "string"
                                    },
                                    "LastDataReceived": {
                                        "type": "string"
                                    },
                                    "InstallationName": {
                                        "type": "string"
                                    },
                                    "Location": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "column_name": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "array",
                        "items": [{
                            "type": "object",
                            "properties": {}
                        }]
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinels_distinct_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinels/distinct/{column_name}"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "where": {
                                "type": "object",
                                "properties": {
                                    "Installation": {
                                        "type": "string"
                                    },
                                    "Status": {
                                        "type": "string"
                                    },
                                    "DeviceName": {
                                        "type": "string"
                                    },
                                    "DeviceID": {
                                        "type": "string"
                                    },
                                    "BatteryLevel": {
                                        "type": "string"
                                    },
                                    "LastTransmission": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "column_name": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "array",
                        "items": [{
                            "type": "object",
                            "properties": {}
                        }]
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinels_query_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinels/query"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "proj": {
                                "type": "object",
                                "properties": {}
                            },
                            "skip": {
                                "type": "number",
                                "default": null
                            },
                            "sort": {
                                "type": "string"
                            },
                            "count": {
                                "type": "boolean",
                                "default": null
                            },
                            "limit": {
                                "type": "number",
                                "default": null
                            },
                            "include": {
                                "type": "string"
                            },
                            "where": {
                                "type": "object",
                                "properties": {
                                    "SerialNumber": {
                                        "type": "string"
                                    },
                                    "DeviceName": {
                                        "type": "string"
                                    },
                                    "installationID": {
                                        "type": "object",
                                        "properties": {
                                            "collName": {
                                                "type": "string"
                                            },
                                            "_id": {
                                                "type": "string"
                                            }
                                        }
                                    },
                                    "BatteryLevel": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "Status": {
                                        "type": "string"
                                    },
                                    "LastTransmission": {
                                        "type": "string"
                                    },
                                    "gatewayDeviceID": {
                                        "type": "object",
                                        "properties": {}
                                    }
                                }
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "array",
                        "items": [{
                            "type": "object",
                            "properties": {
                                "_createdAt": {
                                    "type": "string",
                                    "default": "2023-11-09T23:12:51.519Z"
                                },
                                "installationID": {
                                    "type": "object",
                                    "properties": {
                                        "_id": {
                                            "type": "string",
                                            "default": "654046182e22d731e979375b"
                                        },
                                        "collName": {
                                            "type": "string",
                                            "default": "Installations"
                                        }
                                    }
                                },
                                "BatteryLevel": {
                                    "type": "number",
                                    "default": 75
                                },
                                "acl": {
                                    "type": "object",
                                    "properties": {
                                        "65412f16bde21e3199f840c7": {
                                            "type": "object",
                                            "properties": {
                                                "read": {
                                                    "type": "boolean",
                                                    "default": true
                                                },
                                                "write": {
                                                    "type": "boolean",
                                                    "default": true
                                                }
                                            }
                                        }
                                    }
                                },
                                "_id": {
                                    "type": "string",
                                    "default": "654d67733c62ea7f28332fb2"
                                },
                                "SerialNumber": {
                                    "type": "string",
                                    "default": "SN001"
                                },
                                "Status": {
                                    "type": "string",
                                    "default": "Active"
                                },
                                "LastTransmission": {
                                    "type": "string",
                                    "default": "2023-11-09T15:12:50.316Z"
                                },
                                "DeviceName": {
                                    "type": "string",
                                    "default": "Sentinel 113"
                                },
                                "gatewayDeviceID": {
                                    "type": "object",
                                    "properties": {
                                        "collName": {
                                            "type": "string",
                                            "default": "Gateways"
                                        },
                                        "_id": {
                                            "type": "string",
                                            "default": "65498cde2e22d731e98a8228"
                                        }
                                    }
                                },
                                "_updatedAt": {
                                    "type": "string",
                                    "default": "2023-11-09T23:12:51.519Z"
                                }
                            }
                        }]
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinels_list_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinels"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "count": {
                                "type": "string"
                            },
                            "limit": {
                                "type": "string"
                            },
                            "skip": {
                                "type": "string"
                            },
                            "sort": {
                                "type": "string"
                            },
                            "include": {
                                "type": "string"
                            },
                            "where": {
                                "type": "string"
                            },
                            "proj": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "gatewayDeviceID": {
                                            "type": "object",
                                            "properties": {
                                                "_createdAt": {
                                                    "type": "string"
                                                },
                                                "gatewayName": {
                                                    "type": "string"
                                                },
                                                "lastCheckIn": {
                                                    "type": "string"
                                                },
                                                "acl": {
                                                    "type": "object",
                                                    "properties": {}
                                                },
                                                "ipAddress": {
                                                    "type": "string"
                                                },
                                                "_id": {
                                                    "type": "string"
                                                },
                                                "_updatedAt": {
                                                    "type": "string"
                                                },
                                                "gatewaySentinels": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "commissionDate": {
                                                    "type": "string"
                                                },
                                                "fwVersion": {
                                                    "type": "string"
                                                },
                                                "installationID": {
                                                    "type": "object",
                                                    "properties": {
                                                        "TotalDevices": {
                                                            "type": "number",
                                                            "default": null
                                                        },
                                                        "LastDataReceived": {
                                                            "type": "string"
                                                        },
                                                        "_updatedAt": {
                                                            "type": "string"
                                                        },
                                                        "Notes": {
                                                            "type": "string"
                                                        },
                                                        "Location": {
                                                            "type": "string"
                                                        },
                                                        "GPSCoordinates": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "number",
                                                                "default": null,
                                                                "index": 1
                                                            }, {
                                                                "type": "null"
                                                            }, {
                                                                "type": "number",
                                                                "default": null,
                                                                "index": 0
                                                            }]
                                                        },
                                                        "InstallationName": {
                                                            "type": "string"
                                                        },
                                                        "_createdAt": {
                                                            "type": "string"
                                                        },
                                                        "acl": {
                                                            "type": "object",
                                                            "properties": {}
                                                        },
                                                        "_id": {
                                                            "type": "string"
                                                        },
                                                        "GatewayDeviceID": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "null"
                                                            }]
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "LastTransmission": {
                                            "type": "string"
                                        },
                                        "Status": {
                                            "type": "string"
                                        },
                                        "DeviceName": {
                                            "type": "string"
                                        },
                                        "installationID": {
                                            "type": "object",
                                            "properties": {
                                                "Notes": {
                                                    "type": "string"
                                                },
                                                "LastDataReceived": {
                                                    "type": "string"
                                                },
                                                "_id": {
                                                    "type": "string"
                                                },
                                                "InstallationName": {
                                                    "type": "string"
                                                },
                                                "acl": {
                                                    "type": "object",
                                                    "properties": {}
                                                },
                                                "_createdAt": {
                                                    "type": "string"
                                                },
                                                "GPSCoordinates": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }, {
                                                        "type": "number",
                                                        "default": null,
                                                        "index": 1
                                                    }, {
                                                        "type": "number",
                                                        "default": null,
                                                        "index": 0
                                                    }]
                                                },
                                                "Location": {
                                                    "type": "string"
                                                },
                                                "GatewayDeviceID": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "_updatedAt": {
                                                    "type": "string"
                                                },
                                                "TotalDevices": {
                                                    "type": "number",
                                                    "default": null
                                                }
                                            }
                                        },
                                        "BatteryLevel": {
                                            "type": "number",
                                            "default": null
                                        },
                                        "_updatedAt": {
                                            "type": "string"
                                        },
                                        "acl": {
                                            "type": "object",
                                            "properties": {}
                                        },
                                        "SerialNumber": {
                                            "type": "string"
                                        },
                                        "_id": {
                                            "type": "string"
                                        },
                                        "_createdAt": {
                                            "type": "string"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinels_delete_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinels/{_id}"
            },
            "method": {
                "type": "string",
                "default": "delete"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinel_Sensor_Data_distinct_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinel_Sensor_Data/distinct/{column_name}"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "where": {
                                "type": "object",
                                "properties": {
                                    "Growth": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "Stress": {
                                        "type": "string"
                                    },
                                    "Temperature": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "DeviceID": {
                                        "type": "object",
                                        "properties": {}
                                    },
                                    "Irrigation": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "Signature": {
                                        "type": "string"
                                    },
                                    "Humidity": {
                                        "type": "number",
                                        "default": null
                                    }
                                }
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "column_name": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "array",
                        "items": [{
                            "type": "object",
                            "properties": {}
                        }]
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinel_Sensor_Data_query_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinel_Sensor_Data/query"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "proj": {
                                "type": "object",
                                "properties": {}
                            },
                            "include": {
                                "type": "string",
                                "default": "sentinelID,sentinelID.installationID"
                            },
                            "limit": {
                                "type": "number",
                                "default": null
                            },
                            "skip": {
                                "type": "number",
                                "default": null
                            },
                            "count": {
                                "type": "boolean",
                                "default": null
                            },
                            "sort": {
                                "type": "string"
                            },
                            "where": {
                                "type": "object",
                                "properties": {
                                    "sentinelID._id": {
                                        "type": "object",
                                        "properties": {}
                                    },
                                    "Signature": {
                                        "type": "string"
                                    },
                                    "Stress": {
                                        "type": "string"
                                    },
                                    "Growth": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "Temperature": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "Irrigation": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "Humidity": {
                                        "type": "number",
                                        "default": null
                                    }
                                }
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "array",
                        "items": [{
                            "type": "object",
                            "properties": {
                                "acl": {
                                    "type": "object",
                                    "properties": {}
                                },
                                "Humidity": {
                                    "type": "number",
                                    "default": null
                                },
                                "Signature": {
                                    "type": "string"
                                },
                                "_id": {
                                    "type": "string"
                                },
                                "Stress": {
                                    "type": "string"
                                },
                                "Temperature": {
                                    "type": "number",
                                    "default": null
                                },
                                "sentinelID": {
                                    "type": "object",
                                    "properties": {
                                        "_id": {
                                            "type": "string"
                                        },
                                        "DeviceName": {
                                            "type": "string"
                                        },
                                        "Status": {
                                            "type": "string"
                                        },
                                        "acl": {
                                            "type": "object",
                                            "properties": {}
                                        },
                                        "_createdAt": {
                                            "type": "string"
                                        },
                                        "_updatedAt": {
                                            "type": "string"
                                        },
                                        "BatteryLevel": {
                                            "type": "number",
                                            "default": null
                                        },
                                        "gatewayDeviceID": {
                                            "type": "object",
                                            "properties": {
                                                "fwVersion": {
                                                    "type": "string"
                                                },
                                                "gatewaySentinels": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "_createdAt": {
                                                    "type": "string"
                                                },
                                                "acl": {
                                                    "type": "object",
                                                    "properties": {}
                                                },
                                                "gatewayName": {
                                                    "type": "string"
                                                },
                                                "installationID": {
                                                    "type": "object",
                                                    "properties": {
                                                        "Location": {
                                                            "type": "string"
                                                        },
                                                        "_id": {
                                                            "type": "string"
                                                        },
                                                        "_createdAt": {
                                                            "type": "string"
                                                        },
                                                        "_updatedAt": {
                                                            "type": "string"
                                                        },
                                                        "acl": {
                                                            "type": "object",
                                                            "properties": {}
                                                        },
                                                        "LastDataReceived": {
                                                            "type": "string"
                                                        },
                                                        "Notes": {
                                                            "type": "string"
                                                        },
                                                        "InstallationName": {
                                                            "type": "string"
                                                        },
                                                        "GPSCoordinates": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "number",
                                                                "default": null,
                                                                "index": 1
                                                            }, {
                                                                "type": "number",
                                                                "default": null,
                                                                "index": 0
                                                            }, {
                                                                "type": "null"
                                                            }]
                                                        },
                                                        "GatewayDeviceID": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "null"
                                                            }]
                                                        },
                                                        "TotalDevices": {
                                                            "type": "number",
                                                            "default": null
                                                        }
                                                    }
                                                },
                                                "lastCheckIn": {
                                                    "type": "string"
                                                },
                                                "_updatedAt": {
                                                    "type": "string"
                                                },
                                                "commissionDate": {
                                                    "type": "string"
                                                },
                                                "_id": {
                                                    "type": "string"
                                                },
                                                "ipAddress": {
                                                    "type": "string"
                                                }
                                            }
                                        },
                                        "SerialNumber": {
                                            "type": "string"
                                        },
                                        "LastTransmission": {
                                            "type": "string"
                                        },
                                        "installationID": {
                                            "type": "object",
                                            "properties": {
                                                "_updatedAt": {
                                                    "type": "string"
                                                },
                                                "InstallationName": {
                                                    "type": "string"
                                                },
                                                "_createdAt": {
                                                    "type": "string"
                                                },
                                                "Location": {
                                                    "type": "string"
                                                },
                                                "GPSCoordinates": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }, {
                                                        "type": "number",
                                                        "default": null,
                                                        "index": 0
                                                    }, {
                                                        "type": "number",
                                                        "default": null,
                                                        "index": 1
                                                    }]
                                                },
                                                "LastDataReceived": {
                                                    "type": "string"
                                                },
                                                "acl": {
                                                    "type": "object",
                                                    "properties": {}
                                                },
                                                "GatewayDeviceID": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "_id": {
                                                    "type": "string"
                                                },
                                                "Notes": {
                                                    "type": "string"
                                                },
                                                "TotalDevices": {
                                                    "type": "number",
                                                    "default": null
                                                }
                                            }
                                        }
                                    }
                                },
                                "Irrigation": {
                                    "type": "number",
                                    "default": null
                                },
                                "_updatedAt": {
                                    "type": "string"
                                },
                                "_createdAt": {
                                    "type": "string"
                                },
                                "Growth": {
                                    "type": "number",
                                    "default": null
                                }
                            }
                        }]
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinel_Sensor_Data_create_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinel_Sensor_Data"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "Growth": {
                                "type": "number",
                                "default": 2.2
                            },
                            "Temperature": {
                                "type": "number",
                                "default": 35
                            },
                            "Signature": {
                                "type": "string",
                                "default": "ce85d92884bafdc6d0643eff26fa4d16a3051c0477fe21528c68fe2e05398261"
                            },
                            "Irrigation": {
                                "type": "number",
                                "default": 3.2
                            },
                            "sentinelID": {
                                "type": "object",
                                "properties": {
                                    "collName": {
                                        "type": "string",
                                        "default": "Sentinels"
                                    },
                                    "_id": {
                                        "type": "string",
                                        "default": "6540459c2e22d731e9793750"
                                    }
                                }
                            },
                            "Stress": {
                                "type": "string",
                                "default": "Low"
                            },
                            "Humidity": {
                                "type": "number",
                                "default": 10
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "full_object": {
                                "type": "string",
                                "default": "false"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string"
                            },
                            "_createdAt": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinel_Sensor_Data_list_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinel_Sensor_Data"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "include": {
                                "type": "string",
                                "default": "sentinelID,sentinelID.installationID,sentinelID.gatewayDeviceID,sentinelID.gatewayDeviceID.installationID"
                            },
                            "where": {
                                "type": "string"
                            },
                            "proj": {
                                "type": "string"
                            },
                            "limit": {
                                "type": "string"
                            },
                            "skip": {
                                "type": "string"
                            },
                            "sort": {
                                "type": "string"
                            },
                            "count": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Session-Token": {
                                "type": "string"
                            },
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "sentinelID": {
                                            "type": "object",
                                            "properties": {
                                                "Installation": {
                                                    "type": "string"
                                                },
                                                "DeviceName": {
                                                    "type": "string"
                                                },
                                                "gatewayDeviceID": {
                                                    "type": "object",
                                                    "properties": {
                                                        "_id": {
                                                            "type": "string"
                                                        },
                                                        "commissionDate": {
                                                            "type": "string"
                                                        },
                                                        "_updatedAt": {
                                                            "type": "string"
                                                        },
                                                        "gatewayName": {
                                                            "type": "string"
                                                        },
                                                        "ipAddress": {
                                                            "type": "string"
                                                        },
                                                        "installationID": {
                                                            "type": "object",
                                                            "properties": {
                                                                "Location": {
                                                                    "type": "string"
                                                                },
                                                                "_id": {
                                                                    "type": "string"
                                                                },
                                                                "LastDataReceived": {
                                                                    "type": "string"
                                                                },
                                                                "InstallationName": {
                                                                    "type": "string"
                                                                },
                                                                "GPSCoordinates": {
                                                                    "type": "array",
                                                                    "items": [{
                                                                        "type": "number",
                                                                        "default": null,
                                                                        "index": 0
                                                                    }, {
                                                                        "type": "number",
                                                                        "default": null,
                                                                        "index": 1
                                                                    }, {
                                                                        "type": "null"
                                                                    }]
                                                                },
                                                                "_updatedAt": {
                                                                    "type": "string"
                                                                },
                                                                "TotalDevices": {
                                                                    "type": "number",
                                                                    "default": null
                                                                },
                                                                "InstallationID": {
                                                                    "type": "string"
                                                                },
                                                                "acl": {
                                                                    "type": "object",
                                                                    "properties": {}
                                                                },
                                                                "Notes": {
                                                                    "type": "string"
                                                                },
                                                                "GatewayDeviceID": {
                                                                    "type": "array",
                                                                    "items": [{
                                                                        "type": "null"
                                                                    }]
                                                                },
                                                                "_createdAt": {
                                                                    "type": "string"
                                                                }
                                                            }
                                                        },
                                                        "acl": {
                                                            "type": "object",
                                                            "properties": {}
                                                        },
                                                        "lastCheckIn": {
                                                            "type": "string"
                                                        },
                                                        "_createdAt": {
                                                            "type": "string"
                                                        },
                                                        "gatewaySentinels": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "null"
                                                            }]
                                                        },
                                                        "fwVersion": {
                                                            "type": "string"
                                                        }
                                                    }
                                                },
                                                "LastTransmission": {
                                                    "type": "string"
                                                },
                                                "installationID": {
                                                    "type": "object",
                                                    "properties": {
                                                        "Notes": {
                                                            "type": "string"
                                                        },
                                                        "_id": {
                                                            "type": "string"
                                                        },
                                                        "GPSCoordinates": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "number",
                                                                "default": null,
                                                                "index": 1
                                                            }, {
                                                                "type": "number",
                                                                "default": null,
                                                                "index": 0
                                                            }, {
                                                                "type": "null"
                                                            }]
                                                        },
                                                        "Location": {
                                                            "type": "string"
                                                        },
                                                        "GatewayDeviceID": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "null"
                                                            }]
                                                        },
                                                        "TotalDevices": {
                                                            "type": "number",
                                                            "default": null
                                                        },
                                                        "_updatedAt": {
                                                            "type": "string"
                                                        },
                                                        "_createdAt": {
                                                            "type": "string"
                                                        },
                                                        "acl": {
                                                            "type": "object",
                                                            "properties": {}
                                                        },
                                                        "InstallationName": {
                                                            "type": "string"
                                                        },
                                                        "LastDataReceived": {
                                                            "type": "string"
                                                        },
                                                        "InstallationID": {
                                                            "type": "string"
                                                        }
                                                    }
                                                },
                                                "_id": {
                                                    "type": "string"
                                                },
                                                "Status": {
                                                    "type": "string"
                                                },
                                                "_updatedAt": {
                                                    "type": "string"
                                                },
                                                "BatteryLevel": {
                                                    "type": "number",
                                                    "default": null
                                                },
                                                "acl": {
                                                    "type": "object",
                                                    "properties": {}
                                                },
                                                "_createdAt": {
                                                    "type": "string"
                                                },
                                                "DeviceID": {
                                                    "type": "string"
                                                }
                                            }
                                        },
                                        "Temperature": {
                                            "type": "number",
                                            "default": null
                                        },
                                        "Irrigation": {
                                            "type": "number",
                                            "default": null
                                        },
                                        "_id": {
                                            "type": "string"
                                        },
                                        "_updatedAt": {
                                            "type": "string"
                                        },
                                        "Stress": {
                                            "type": "string"
                                        },
                                        "Humidity": {
                                            "type": "number",
                                            "default": null
                                        },
                                        "Growth": {
                                            "type": "number",
                                            "default": null
                                        },
                                        "Signature": {
                                            "type": "string"
                                        },
                                        "_createdAt": {
                                            "type": "string"
                                        },
                                        "acl": {
                                            "type": "object",
                                            "properties": {}
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "Sentinel_Sensor_Data_read_service": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.appery.io/rest/1/db/collections/Sentinel_Sensor_Data/{_id}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string"
                            },
                            "include": {
                                "type": "string",
                                "default": "DeviceID,DeviceID.installationID,DeviceID.gatewayDeviceID,DeviceID.gatewayDeviceID.installationID"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "X-Appery-Database-Id": {
                                "type": "string",
                                "default": "{CleanForge_Sentinels_App_settings.database_id}"
                            },
                            "X-Appery-Session-Token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "Signature": {
                                        "type": "string"
                                    },
                                    "Irrigation": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "_createdAt": {
                                        "type": "string"
                                    },
                                    "Growth": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "_id": {
                                        "type": "string"
                                    },
                                    "DeviceID": {
                                        "type": "object",
                                        "properties": {
                                            "LastTransmission": {
                                                "type": "string"
                                            },
                                            "Installation": {
                                                "type": "string"
                                            },
                                            "gatewayDeviceID": {
                                                "type": "object",
                                                "properties": {
                                                    "ipAddress": {
                                                        "type": "string"
                                                    },
                                                    "acl": {
                                                        "type": "object",
                                                        "properties": {}
                                                    },
                                                    "_createdAt": {
                                                        "type": "string"
                                                    },
                                                    "_updatedAt": {
                                                        "type": "string"
                                                    },
                                                    "fwVersion": {
                                                        "type": "string"
                                                    },
                                                    "_id": {
                                                        "type": "string"
                                                    },
                                                    "commissionDate": {
                                                        "type": "string"
                                                    },
                                                    "gatewayName": {
                                                        "type": "string"
                                                    },
                                                    "installationID": {
                                                        "type": "object",
                                                        "properties": {
                                                            "TotalDevices": {
                                                                "type": "number",
                                                                "default": null
                                                            },
                                                            "GatewayDeviceID": {
                                                                "type": "array",
                                                                "items": [{
                                                                    "type": "null"
                                                                }]
                                                            },
                                                            "InstallationID": {
                                                                "type": "string"
                                                            },
                                                            "GPSCoordinates": {
                                                                "type": "array",
                                                                "items": [{
                                                                    "type": "number",
                                                                    "default": null,
                                                                    "index": 1
                                                                }, {
                                                                    "type": "null"
                                                                }, {
                                                                    "type": "number",
                                                                    "default": null,
                                                                    "index": 0
                                                                }]
                                                            },
                                                            "_updatedAt": {
                                                                "type": "string"
                                                            },
                                                            "InstallationName": {
                                                                "type": "string"
                                                            },
                                                            "Notes": {
                                                                "type": "string"
                                                            },
                                                            "acl": {
                                                                "type": "object",
                                                                "properties": {}
                                                            },
                                                            "_id": {
                                                                "type": "string"
                                                            },
                                                            "Location": {
                                                                "type": "string"
                                                            },
                                                            "_createdAt": {
                                                                "type": "string"
                                                            },
                                                            "LastDataReceived": {
                                                                "type": "string"
                                                            }
                                                        }
                                                    },
                                                    "lastCheckIn": {
                                                        "type": "string"
                                                    },
                                                    "gatewaySentinels": {
                                                        "type": "array",
                                                        "items": [{
                                                            "type": "null"
                                                        }]
                                                    }
                                                }
                                            },
                                            "_updatedAt": {
                                                "type": "string"
                                            },
                                            "DeviceID": {
                                                "type": "string"
                                            },
                                            "installationID": {
                                                "type": "object",
                                                "properties": {
                                                    "Location": {
                                                        "type": "string"
                                                    },
                                                    "_updatedAt": {
                                                        "type": "string"
                                                    },
                                                    "acl": {
                                                        "type": "object",
                                                        "properties": {}
                                                    },
                                                    "GatewayDeviceID": {
                                                        "type": "array",
                                                        "items": [{
                                                            "type": "null"
                                                        }]
                                                    },
                                                    "LastDataReceived": {
                                                        "type": "string"
                                                    },
                                                    "InstallationID": {
                                                        "type": "string"
                                                    },
                                                    "GPSCoordinates": {
                                                        "type": "array",
                                                        "items": [{
                                                            "type": "number",
                                                            "default": null,
                                                            "index": 1
                                                        }, {
                                                            "type": "number",
                                                            "default": null,
                                                            "index": 0
                                                        }, {
                                                            "type": "null"
                                                        }]
                                                    },
                                                    "_createdAt": {
                                                        "type": "string"
                                                    },
                                                    "Notes": {
                                                        "type": "string"
                                                    },
                                                    "TotalDevices": {
                                                        "type": "number",
                                                        "default": null
                                                    },
                                                    "InstallationName": {
                                                        "type": "string"
                                                    },
                                                    "_id": {
                                                        "type": "string"
                                                    }
                                                }
                                            },
                                            "BatteryLevel": {
                                                "type": "number",
                                                "default": null
                                            },
                                            "acl": {
                                                "type": "object",
                                                "properties": {}
                                            },
                                            "DeviceName": {
                                                "type": "string"
                                            },
                                            "Status": {
                                                "type": "string"
                                            },
                                            "_createdAt": {
                                                "type": "string"
                                            },
                                            "_id": {
                                                "type": "string"
                                            }
                                        }
                                    },
                                    "_updatedAt": {
                                        "type": "string"
                                    },
                                    "acl": {
                                        "type": "object",
                                        "properties": {}
                                    },
                                    "Humidity": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "Temperature": {
                                        "type": "number",
                                        "default": null
                                    },
                                    "Stress": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "BarcodeScannerService": {
        "type": "object",
        "properties": {
            "request": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {
                            "prompt": {
                                "type": "string"
                            },
                            "resultDisplayDuration": {
                                "type": "number",
                                "default": null
                            },
                            "formats": {
                                "type": "string"
                            },
                            "disableAnimations": {
                                "type": "boolean",
                                "default": null
                            },
                            "disableSuccessBeep": {
                                "type": "boolean",
                                "default": null
                            },
                            "showFlipCameraButton": {
                                "type": "boolean",
                                "default": null
                            },
                            "preferFrontCamera": {
                                "type": "boolean",
                                "default": null
                            },
                            "showTorchButton": {
                                "type": "boolean",
                                "default": null
                            },
                            "torchOn": {
                                "type": "boolean",
                                "default": null
                            },
                            "orientation": {
                                "type": "string",
                                "default": "none"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {
                            "cancelled": {
                                "type": "boolean",
                                "default": null
                            },
                            "format": {
                                "type": "string"
                            },
                            "text": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        }
    },
    "GoogleLogoutService": {
        "type": "object",
        "properties": {
            "request": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {}
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {
                            "status": {
                                "type": "string"
                            },
                            "message": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        }
    },
    "NFCScannerService": {
        "type": "object",
        "properties": {
            "request": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {}
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {
                            "payload": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        }
    },
    "GoogleLoginService": {
        "type": "object",
        "properties": {
            "request": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {
                            "webClientId": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {
                            "idToken": {
                                "type": "string"
                            },
                            "serverAuthCode": {
                                "type": "string"
                            },
                            "givenName": {
                                "type": "string"
                            },
                            "email": {
                                "type": "string"
                            },
                            "familyName": {
                                "type": "string"
                            },
                            "userId": {
                                "type": "string"
                            },
                            "expires_in": {
                                "type": "number",
                                "default": null
                            },
                            "imageUrl": {
                                "type": "string"
                            },
                            "displayName": {
                                "type": "string"
                            },
                            "expires": {
                                "type": "number",
                                "default": null
                            },
                            "accessToken": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        }
    },
    "LoadUserInstallationsLocal_service": {
        "type": "object",
        "properties": {
            "request": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {}
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    }
};
/**
 * Data storage
 */
export const _aioDefStorageValues = {
    variables: {
        "langs": ['en', 'es']
    },
    storages: {
    },
    functions: {
        saveUserDataAndNavigate: async function(loginResult) {
            this.$v.userId = loginResult._id;
            this.$a.db.setSessionToken(loginResult.sessionToken);
            this.$a.db.userSetId(this.$v.userId);
            this.$v.user = loginResult;
            this.$v.userSessionToken = loginResult.sessionToken;
            this.$v.userInstallations = [];
            const websiteUrl = "https://www.cleanforge.ca/#pricing";
            this.$v.profile = (await this.$a.db.query("Profiles", {
                where: {
                    userId: this.$v.userId
                }
            }))[0];
            let userData = await this.$a.db.userGetData();
            let db_id = this.$a.config.get("CleanForge_Sentinels_App_settings.database_id", "653df7280f0d316659d4aaec");
            await this.$v.fetchUserInstallations(this.$v.userSessionToken, db_id);
            if (userData.subscriptionStatus === "active" || userData.subscriptionStatus === "trial") {
                if (userData.onboardingComplete !== true) {
                    this.$a.navigation.root("Onboarding");
                } else {
                    await this.$a.dismissLoading();
                    this.$a.navigation.root("Dashboard");
                }
            } else {
                this.$a.navigation.root("Subscribe");
            }
        },
        saveAutologinData: async function(autoLogin, userCredentials?) {
            this.$a.setLocal("autoLogin", autoLogin);
            if (userCredentials) await this.$a.ssSet("savedUser", userCredentials);
        },
        logout: async function() {
            try {
                await this.$a.db.logout();
            } catch (e) {
                console.log(e);
            }
            await this.$v.saveAutologinData(false);
            this.$v.userId = "";
            this.$v.user = null;
            this.$v.profile = null;
            this.$v.userSessionToken = "";
            this.$v.userSentinels = [];
            this.$v.userInstallations = [];
            await this.$a.data.clearStorage();
            this.$a.navigation.root("Login");
        },
        checkBiometric: function() {
            return new Promise(function(resolve, reject) {
                if (!window.Fingerprint) {
                    resolve(false);
                    return;
                }
                window.Fingerprint.isAvailable(() => {
                    resolve(true);
                }, (error) => {
                    console.log(error);
                    resolve(false);
                });
            })
        },
        biometricLogin: function() {
            return new Promise(function(resolve, reject) {
                if (!window.Fingerprint) {
                    resolve(false);
                    return;
                }
                window.Fingerprint.show({
                    description: "Some biometric description"
                }, () => {
                    resolve(true)
                }, (error) => {
                    console.log(error);
                    resolve(false);
                });
            })
        },
        resizeImage: function(base64image, width, height) {
            return new Promise((resolve, reject) => {
                if (!base64image.startsWith('data:')) {
                    base64image = "data:;base64," + base64image;
                }
                let img = new Image();
                img.src = base64image;
                img.onload = () => {
                    height = height || img.height;
                    width = width || img.width;
                    if (img.height > img.width) {
                        width = Math.floor(height * (img.width / img.height));
                    } else {
                        height = Math.floor(width * (img.height / img.width));
                    }
                    // Now do final resize for the image to meet the dimension requirments
                    // directly to the output canvas, that will output the final image
                    let outputCanvas: HTMLCanvasElement = document.createElement('canvas');
                    let outputCanvasContext = outputCanvas.getContext("2d");
                    outputCanvas.width = width;
                    outputCanvas.height = height;
                    outputCanvasContext.drawImage(img, 0, 0, img.width, img.height,
                        0, 0, width, height);
                    // output the canvas pixels as an image. params: format, quality
                    let quality = 1;
                    let base64ResizedImage = outputCanvas.toDataURL('image/jpeg', quality);
                    resolve(base64ResizedImage);
                };
            });
        },
        fetchUserInstallations: async function(sessionToken, db_id) {
            try {
                const service = await this.$a.getService("Installations_list_service");
                if (!service) {
                    console.log("Error. Installations List Service was not found.");
                    return;
                }
                // Convert Observable to a Promise using toPromise()
                const res = await service.execute({
                    data: {},
                    params: {},
                    headers: {
                        "X-Appery-Database-Id": db_id,
                        "X-Appery-Session-Token": sessionToken
                    }
                }).toPromise();
                this.$v.userInstallations = res;
                return res;
            } catch (err) {
                console.error("Error fetching User Installations: ", err);
                throw err; // Re-throw the error to be handled by the caller
            }
        }
    }
}