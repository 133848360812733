/*
  See https://angular.io/guide/router for more info on guards.
*/


import { Injectable } from '@angular/core';
import {  CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, UrlSegment } from '@angular/router';
import { Observable } from "rxjs/Observable";
import { ApperyioHelperService } from '../apperyio/apperyio_helper';

@Injectable({
  providedIn: 'root',
})
  

export class NavigationGuard implements CanActivate {
    private $a: ApperyioHelperService;
    private $v: any;
    constructor(private Apperyio: ApperyioHelperService, private router: Router) {
      this.$a = this.Apperyio;
      this.$v = this.Apperyio.vars;
    }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        
        if (this.$a.lgDown && next.url[0].path !== "tabs") {
            this.Apperyio.navigateTo("tabs/" + next.url.join("/"));
            return;
        }

        return true;
    }
}

  

/*
    Guard class should be exported as ExportedClass
*/
export let ExportedClass = NavigationGuard;
