import {
    NgModule
} from '@angular/core';
import {
    DeviceListComponentModule
} from '../custom_component/DeviceList/DeviceList.module';
import {
    CreateInstallationModalComponentModule
} from '../custom_component/CreateInstallationModal/CreateInstallationModal.module';
import {
    CreateDeviceModalComponentModule
} from '../custom_component/CreateDeviceModal/CreateDeviceModal.module';
import {
    DeleteConfirmationModalComponentModule
} from '../custom_component/DeleteConfirmationModal/DeleteConfirmationModal.module';
@NgModule({
    declarations: [],
    exports: [
        DeviceListComponentModule,
        CreateInstallationModalComponentModule,
        CreateDeviceModalComponentModule,
        DeleteConfirmationModalComponentModule,
    ],
    imports: []
})
export class CustomComponentsModule {}