import {
    NgModule
} from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';
import {
    ExportedClass as SecurityGuard
} from './scripts/custom/SecurityGuard';
import {
    ExportedClass as NavigationGuard
} from './scripts/custom/NavigationGuard';
const routes: Routes = [{
        path: '',
        redirectTo: 'launch',
        pathMatch: 'full'
    },
    {
        path: 'launch',
        loadChildren: () =>
            import ('./Launch/Launch.module').then(m => m.LaunchPageModule),
    },
    {
        path: 'login',
        loadChildren: () =>
            import ('./LoginScreen/LoginScreen.module').then(m => m.LoginScreenPageModule),
    },
    {
        path: 'confirmationcode',
        loadChildren: () =>
            import ('./ConfirmationCode/ConfirmationCode.module').then(m => m.ConfirmationCodePageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'profile',
        loadChildren: () =>
            import ('./Profile/Profile.module').then(m => m.ProfilePageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'privacypolicy',
        loadChildren: () =>
            import ('./PrivacyPolicy/PrivacyPolicy.module').then(m => m.PrivacyPolicyPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'settings',
        loadChildren: () =>
            import ('./Settings/Settings.module').then(m => m.SettingsPageModule),
        canActivate: [SecurityGuard, NavigationGuard],
    },
    {
        path: 'onboarding',
        loadChildren: () =>
            import ('./Onboarding/Onboarding.module').then(m => m.OnboardingPageModule),
    },
    {
        path: 'tabs',
        loadChildren: () =>
            import ('./Tabs/Tabs.module').then(m => m.TabsPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'devices',
        loadChildren: () =>
            import ('./Devices/Devices.module').then(m => m.DevicesPageModule),
        canActivate: [NavigationGuard, SecurityGuard],
    },
    {
        path: 'devicedetails/:deviceID',
        loadChildren: () =>
            import ('./DeviceDetails/DeviceDetails.module').then(m => m.DeviceDetailsPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'forgotpassword',
        loadChildren: () =>
            import ('./ForgotPassword/ForgotPassword.module').then(m => m.ForgotPasswordPageModule),
    },
    {
        path: 'scandeviceqr',
        loadChildren: () =>
            import ('./ScanDeviceQR/ScanDeviceQR.module').then(m => m.ScanDeviceQRPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'notes',
        loadChildren: () =>
            import ('./Notes/Notes.module').then(m => m.NotesPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import ('./Dashboard/Dashboard.module').then(m => m.DashboardPageModule),
        canActivate: [NavigationGuard, SecurityGuard],
    },
    {
        path: 'carbon',
        loadChildren: () =>
            import ('./CarbonDash/CarbonDash.module').then(m => m.CarbonDashPageModule),
        canActivate: [NavigationGuard, SecurityGuard],
    },
    {
        path: 'scandeviceble',
        loadChildren: () =>
            import ('./ScanDeviceBLE/ScanDeviceBLE.module').then(m => m.ScanDeviceBLEPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'installations',
        loadChildren: () =>
            import ('./Installations/Installations.module').then(m => m.InstallationsPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'installationdetails/:installationID',
        loadChildren: () =>
            import ('./InstallationDetails/InstallationDetails.module').then(m => m.InstallationDetailsPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'scandevicenfc',
        loadChildren: () =>
            import ('./ScanDeviceNFC/ScanDeviceNFC.module').then(m => m.ScanDeviceNFCPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'termsofservice',
        loadChildren: () =>
            import ('./TermsOfService/TermsOfService.module').then(m => m.TermsOfServicePageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'gatewaymanagement',
        loadChildren: () =>
            import ('./GatewayManagement/GatewayManagement.module').then(m => m.GatewayManagementPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'installationalerts',
        loadChildren: () =>
            import ('./InstallationAlerts/InstallationAlerts.module').then(m => m.InstallationAlertsPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'datavisualization',
        loadChildren: () =>
            import ('./DataVisualization/DataVisualization.module').then(m => m.DataVisualizationPageModule),
        canActivate: [SecurityGuard],
    },
    {
        path: 'signup',
        loadChildren: () =>
            import ('./Signup/Signup.module').then(m => m.SignupPageModule),
    },
    {
        path: 'subscribe',
        loadChildren: () =>
            import ('./BuySubscription/BuySubscription.module').then(m => m.BuySubscriptionPageModule),
        canActivate: [SecurityGuard],
    },
];
@NgModule({
    imports: [RouterModule.forRoot(
        routes, {
            enableTracing: false,
            useHash: true
        }
    )],
    exports: [RouterModule]
})
export class AppRoutingModule {}