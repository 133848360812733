import {
    NgModule
} from '@angular/core';
import {
    ExportedClass as CoreModule
} from './custom/CoreModule';
@NgModule({
    declarations: [],
    imports: [],
    exports: [
        CoreModule,
    ]
})
export class CustomModulesModule {}