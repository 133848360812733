import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../../scripts/apperyio/apperyio_mapping_helper';
import {
    Input
} from '@angular/core';
import {
    $aio_empty_object
} from '../../scripts/interfaces';
import {
    Output
} from '@angular/core';
import {
    EventEmitter
} from '@angular/core';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'CreateInstallationModal.html',
    selector: 'component-create-installation-modal',
    styleUrls: ['CreateInstallationModal.css', 'CreateInstallationModal.scss']
})
export class CreateInstallationModal {
    @Input() public triggerId: string;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    @ViewChild('j_924', {
        static: false
    }) public j_924;
    @ViewChild('j_930', {
        static: false
    }) public j_930;
    @ViewChild('j_936', {
        static: false
    }) public j_936;
    @ViewChild('j_942', {
        static: false
    }) public j_942;
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    async button1_copy2Click__j_914(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.$a.closeModal();
    }
    async confirmbutton_copy1Click__j_945(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Invoke data service */
        this.invokeService_submitNewInstallation();
        /* Run TypeScript */
        this.$a.closeModal();
    }
    async cancelbutton_copy1Click__j_947(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.$a.closeModal();
    }
    private $aio_dataServices = {
        "submitNewInstallation": "invokeService_submitNewInstallation"
    }
    invokeService_submitNewInstallation(cb?: Function) {
        this.Apperyio.getService("Installations_create_service").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['X-Appery-Session-Token'], __aio_tmp_val__ = this.$aio_mappingHelper.getServiceDataValue("userSessionToken", []));
                data = this.$aio_mappingHelper.updateData(data, ["InstallationName"], this.$aio_mappingHelper.getComponentPropValue.call(this, 'j_924', 'ionic5inputitem', 'value'));
                data = this.$aio_mappingHelper.updateData(data, ["Location"], this.$aio_mappingHelper.getComponentPropValue.call(this, 'j_930', 'ionic5inputitem', 'value'));
                data = this.$aio_mappingHelper.updateData(data, ["GPSCoordinates"], ((value) => {
                    // Split the string by comma
                    const parts = value.split(',');
                    // Check if we have exactly two parts and both parts are valid numbers
                    if (parts.length === 2 && !isNaN(parseFloat(parts[0])) && !isNaN(parseFloat(parts[1]))) {
                        // Parse the latitude and longitude as numbers and return them in an array
                        const latitude = parseFloat(parts[0].trim());
                        const longitude = parseFloat(parts[1].trim());
                        // Return as [longitude, latitude]
                        return [longitude, latitude];
                    } else {
                        // Return null if the input string is not a valid GPS coordinate
                        return [0, 0];
                    }
                })(this.$aio_mappingHelper.getComponentPropValue.call(this, 'j_936', 'ionic5inputitem', 'value')));
                data = this.$aio_mappingHelper.updateData(data, ["Notes"], this.$aio_mappingHelper.getComponentPropValue.call(this, 'j_942', 'ionic5inputitem', 'value'));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
}