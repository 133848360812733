import {
    NgModule
} from '@angular/core';
import {
    IonicModule
} from '@ionic/angular';
@NgModule({
    declarations: [],
    imports: [IonicModule],
    exports: []
})
export class DirectivesModule {}